import { h } from 'preact'
import { WidgetContext } from '../../context'
import { LegalTermsUpdateRemind } from './LegalTermsUpdateRemind'
import { LegalTermsUpdateRemindService, LegalTermsUpdateRemindServiceMock } from './service'
// import { ExpirationRemindServiceMock } from './__mock__/service.mock'
// import './style.scss'
import { buildUrls } from './urls'

export function LegalTermsUpdateRemindWrapper() {
    return <WidgetContext.Consumer>
        {context => {
            const {
                accountServerBase,
                http,
                clientId,
                from,
                mockLegalTermsUpdate,
                serverBase,
                debug,
                registerService } = context
            const urls = buildUrls({ accountServerBase, serverBase })
            let service = new LegalTermsUpdateRemindService(http, urls, clientId, from)
            if (mockLegalTermsUpdate) {
                service = new LegalTermsUpdateRemindServiceMock(http, urls, clientId, from)
            }
            registerService('expiration', service)
            return <LegalTermsUpdateRemind service={service} debug={debug} />
        }}
    </WidgetContext.Consumer>
}
