import urlJoin from 'url-join'

export function buildUrls(data: { accountServerBase: string, serverBase: string }) {
    return {
        getQueryMultiTenantGuide: urlJoin(data.accountServerBase, '/user-service/tenant-user/guidance/info'),
        putMultiTenantGuide: urlJoin(data.accountServerBase, '/user-service/tenant-user/guidance/info'),
    }
}

export type IUrls = ReturnType<typeof buildUrls>
