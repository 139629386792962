import urlJoin from 'url-join'
import type { AxiosInstance } from 'axios';

interface IPostAppLogParams {
    action: string
    [key: string]: string | number
}

export class LabService {
    constructor(
        private axios: AxiosInstance,
        private serviceBase: string,
        private anchor_id: string) {
    }
    postAppLog(params: IPostAppLogParams) {
        return this.axios.request({
            method: 'post',
            url: urlJoin(this.serviceBase, '/lab-service/apps/app/log'),
            params: {
                anchor_id: this.anchor_id,
            },
            data: params,
            withCredentials: false,
        }).then(res => res.data)
    }
}

export class LabServiceEmpty extends LabService {
    constructor(
        axios: AxiosInstance,
        serviceBase: string,
        anchor_id: string) {
        super(axios, serviceBase, anchor_id)
        console.log('use LabServiceEmpty')
    }
    postAppLog(params: IPostAppLogParams) {
        console.log('LabServiceEmpty:', params)
        return Promise.resolve()
    }
}
