import { h, Component } from 'preact'

import Warning from '../../assets/warning.svg'
import { Alert } from '../../components/Alert'
import { Translation } from '../../preact-i18n'
import { formatDate } from '../../utils/datetime'
import type { ExpirationRemindService } from './service'
import type { IPrivilegeRenewItem } from './types'

interface IExpirationRemindProps {
    service: ExpirationRemindService
    debug?: boolean
}

export class ExpirationRemind extends Component<IExpirationRemindProps> {
    state = {
        loading: false,
        error: false,
        data: null as IPrivilegeRenewItem,
        closed: false,
    }

    private setError = (error) => {
        const {
            debug,
        } = this.props
        if (debug) {
            console.error('ExpirationRemind:', error)
        }
        this.setState({
            error,
        })
    }

    componentWillMount() {
        const {
            service,
        } = this.props
        this.setState({
            loading: true,
        })
        service.getPrivilegeRenew().then(data => {
            // 服务器可能没有数据
            if (!data) {
                return
            }
            this.setState({
                data,
            })
        }, this.setError).finally(() => {
            this.setState({
                loading: false,
            })
        })
    }

    onClose = () => {
        const {
            service,
        } = this.props
        const {
            data,
        } = this.state
        service.putPrivilegeRenew(data).catch(this.setError)
        this.setState({
            closed: true,
        })
    }

    render() {
        const {
            error,
            loading,
            data,
            closed,
        } = this.state
        if (closed) {
            return null
        }
        if (!loading && !error && data && data.notice) {
            return (
                <Translation>
                    {(t, { dayjs_locale }) => {
                        const date = formatDate(data.expired_at, dayjs_locale)
                        return <Alert
                            type="warning"
                            data-info="expiration-remind"
                            closable
                            onClose={this.onClose}
                            iconGlyph={Warning.id}
                            title={t(data.tip_code, { date })}
                        />
                    }}
                </Translation>)
        } 
        // todo!!
        return null
        
    }
}
