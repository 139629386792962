import { h } from 'preact'
import { WidgetContext } from '../../context'
import { UnauthorizedLoginRemind } from './UnauthorizedLoginRemind'
import { UnauthorizedLoginRemindService, UnauthorizedLoginRemindServiceMock } from './UnauthorizedLoginRemindService'
import { buildUrls } from './urls'
import { E_Region } from '../../utils/environment'

export function UnauthorizedLoginRemindWrapper() {
    return <WidgetContext.Consumer>
        {context => {
            const {
                accountServerBase,
                backofficeUri,
                http,
                mockUnauthorizedLogin,
                debug,
                region,
                registerService,
            } = context
            if(region !== E_Region.CN) return null
            const urls = buildUrls({ accountServerBase, backofficeUri })
            let service = new UnauthorizedLoginRemindService(http, urls)
            if (mockUnauthorizedLogin) {
                service = new UnauthorizedLoginRemindServiceMock(http, urls)
            }
            registerService('expiration', service)
            return <UnauthorizedLoginRemind service={service} debug={debug} />
        }}
    </WidgetContext.Consumer>
}