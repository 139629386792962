import type { Http } from '../../http'
import type { IGetBaseInfo, IGetQiyuOrZendeskInfo, IQiyuConfig, IZendeskConfig } from './type'
import type { IUrls } from './urls'
import { TokenService } from '../../services/TokenService'

export class QiyuOrZendeskService {
    constructor(
        private http: Http,
        private urls: IUrls,
        private qiyuConfig?: IQiyuConfig,
        private zendeskConfig?: IZendeskConfig
    ) {
    }

    private tokenService: TokenService = new TokenService()

    get QiyuConfig() {
        return this.qiyuConfig
    }

    get ZendeskConfig() {
        return this.zendeskConfig
    }

    getBackofficeUri(){
        return this.urls.backofficeUri
    }

    getQiyuProps(qiyuOrZendeskInfo:IGetQiyuOrZendeskInfo, baseInfo:IGetBaseInfo) {
        return {
            ...qiyuOrZendeskInfo,
            ...baseInfo,
            config: this.qiyuConfig,
        }
    }

    getZendeskProps(qiyuOrZendeskInfo:IGetQiyuOrZendeskInfo, baseInfo:IGetBaseInfo) {
        return {
            ...qiyuOrZendeskInfo,
            ...baseInfo,
            config: this.zendeskConfig,
        }
    }

    getQiyuOrZendeskInfo() {
        const url = this.urls.getQiyuOrZendeskInfo
        return this.http.request<IGetQiyuOrZendeskInfo>(url, {
            method: 'get',
            withCredentials: false,
        }).then(data => {
            return data
        })
    }

    getBaseInfo() {
        const url = this.urls.getBaseInfo
        return this.http.request<IGetBaseInfo>(url, {
            method: 'get',
            withCredentials: false,
        }).then(data => {
            return data
        })
    }

    setQiyuOrZendeskInfoToLocal(qiyuOrZendeskInfo: IGetQiyuOrZendeskInfo) {
        localStorage.setItem('COMMON_WIDGET_QI_YU_OR_ZEN_DESK_INFO', JSON.stringify({
            time: Date.now(),
            data: qiyuOrZendeskInfo,
        }))
    }
    
    getQiyuOrZendeskInfoFromLocal() {
        const qiyuOrZendeskInfo = localStorage.getItem('COMMON_WIDGET_QI_YU_OR_ZEN_DESK_INFO')
        if (qiyuOrZendeskInfo) {
            try {
                const qiyuOrZendeskInfoData = JSON.parse(qiyuOrZendeskInfo)
                if (qiyuOrZendeskInfoData.time > Date.now() - 1000 * 60 * 60 * 24) {
                    return qiyuOrZendeskInfoData.data
                }
            } catch (error) {
                console.log(error)
            }
        }
        return null
    }

    setBaseInfoToLocal(baseInfo: IGetBaseInfo) {
        const session = this.getSessionId()
        if(session){
            localStorage.setItem('COMMON_WIDGET_QI_YU_OR_ZEN_DESK_BASE_INFO', JSON.stringify({
                session,
                data: baseInfo,
            }))
        }
    }

    getBaseInfoFromLocal() {
        const baseInfo = localStorage.getItem('COMMON_WIDGET_QI_YU_OR_ZEN_DESK_BASE_INFO')
        const session = this.getSessionId()
        if (baseInfo) {
            try {
                const baseInfoData = JSON.parse(baseInfo)
                if (baseInfoData.session === session) {
                    return baseInfoData.data
                }
            } catch (error) {
                console.log(error)
            }
        }
        return null
    }

    getSessionId() {
        return this.tokenService.session
    }

    async getInfo() {
        let qiyuOrZendeskInfo = this.getQiyuOrZendeskInfoFromLocal()
        if(!qiyuOrZendeskInfo){
            qiyuOrZendeskInfo = await this.getQiyuOrZendeskInfo()
            this.setQiyuOrZendeskInfoToLocal(qiyuOrZendeskInfo)
        }
        let baseInfo = this.getBaseInfoFromLocal()
        if(!baseInfo && this.tokenService.isTokenValid && !this.tokenService.isAnonymous){
            try {
                baseInfo = await this.getBaseInfo()
                this.setBaseInfoToLocal(baseInfo)
            } catch (e) {
                baseInfo = {} as IGetBaseInfo
            }
        }
        return {
            qiyuOrZendeskInfo,
            baseInfo,
        }
    }
}

export class QiyuOrZendeskServiceMock extends QiyuOrZendeskService {
    async getInfo() {
        return {
            qiyuOrZendeskInfo: {
                need_track: '1',
                qiyu_key: 'xxxxxxxxx',
                zendesk_admin_user: 'xxxx@patsnap.com',
                zendesk_api_token: 'xxxxxxxxxxxxxxxxxx',
                zendesk_domain: 'patsnap.xxxxxxxxxxxx.com',
                zendesk_key: 'xxxxxxxxxxxxxx',
            },
            baseInfo: {},
        }
    }
}