import { h, Component } from 'preact'
import { Msgbox } from '../../components/MsgBox'

import { Translation } from '../../preact-i18n'
import type { LegalTermsUpdateRemindService } from './service'
import TermsOfServicePng from '../../assets/termsOfService.png'

interface ILegalTermsUpdateRemindProps {
    service: LegalTermsUpdateRemindService
    debug?: boolean
}

export class LegalTermsUpdateRemind extends Component<ILegalTermsUpdateRemindProps> {
    state = {
        loading: false,
        error: false,
        data: null,
        closed: false,
    }

    private setError = (error) => {
        const {
            debug,
        } = this.props
        if (debug) {
            console.error('LegalTermsUpdateRemind:', error)
        }
        this.setState({
            error,
        })
    }

    componentWillMount() {
        const { service } = this.props
        this.setState({
            loading: true,
        })

        service.getQueryLegalTermsUpdate().then(data => {
            if (!data) {
                return
            }
            this.setState({
                data,
            })
        }, this.setError).finally(() => {
            this.setState({
                loading: false,
            })
        })
    }

    onCancel = () => {
        const { service } = this.props
        service.handleLogout()
    }

    onConfirm = () => {
        const {
            service,
        } = this.props
        this.setState({
            closed: true,
        })
        service.putQueryLegalTermsUpdate().catch(this.setError)
    }

    render(props: ILegalTermsUpdateRemindProps) {
        const {
            error,
            loading,
            data,
            closed,
        } = this.state
        if (closed) {
            return null
        }
        const {
            TermsOfService,
            PrivacyPolicy,
        } = props.service
        if (!loading && !error && data) {
            return (
                <Translation>
                    {(t) => {
                        const msg = t('legalTermsUpdateRemind.msgBox.content', { termsOfService: TermsOfService, privacyPolicy: PrivacyPolicy })
                        return <Msgbox
                            img={TermsOfServicePng}
                            title={t('legalTermsUpdateRemind.msgBox.title')}
                            message={msg}
                            confirmButtonText={t('legalTermsUpdateRemind.msgBox.confirm')}
                            cancelButtonText={t('legalTermsUpdateRemind.msgBox.disAgree')}
                            onCancel={this.onCancel}
                            onConfirm={this.onConfirm}
                        />
                    }}
                </Translation>
            )
        } 
        // todo!!
        return null
        
    }
}