// 切换为最小的lodash导入
// 人肉维护使用的lodash函数

import get from 'lodash/get'
import merge from 'lodash/merge'
import has from 'lodash/has'
import isFunction from 'lodash/isFunction'
import defaults from 'lodash/defaults'
import isNil from 'lodash/isNil'
import toLower from 'lodash/toLower'
import toUpper from 'lodash/toUpper'
import includes from 'lodash/includes'
import map from 'lodash/map'
import isObject from 'lodash/isObject'
import split from 'lodash/split'
// import isNull from 'lodash/isNull'
// import isUndefined from 'lodash/isUndefined'
// import pick from 'lodash/pick'
// import defaultTo from 'lodash/defaultTo'
// import each from 'lodash/each'
// import isString from 'lodash/isString'
// import isArray from 'lodash/isArray'
import trim from 'lodash/trim'
// import isEmpty from 'lodash/isEmpty'
// import find from 'lodash/find'
import template from 'lodash/template'
import upperFirst from 'lodash/upperFirst'

export default {
    get,
    merge,
    has,
    defaults,
    isFunction,
    isNil,
    toLower,
    toUpper,
    includes,
    map,
    isObject,
    split,
    upperFirst,
    // isNull,
    // isUndefined,
    // pick,
    // defaultTo,
    // each,
    // isString,
    // isArray,
    trim,
    // isEmpty,
    // find,
    // merge,
    template,
}
