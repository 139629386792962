import { h } from 'preact'
import { WidgetContext } from '../../context'
import { buildUrls } from './urls'
import { AiTaggingTourService, AiTaggingTourServiceMock } from './service'
import { AiTaggingTour } from './AiTaggingTour'

export function AiTaggingTourWrapper() {
    return <WidgetContext.Consumer>
        {context => {
            const {
                workspaceServerBase,
                workspaceUrl,
                http,
                mockPublish,
                registerService } = context
            const urls = buildUrls(workspaceServerBase)
            let service = new AiTaggingTourService(http, urls)
            if (mockPublish) {
                service = new AiTaggingTourServiceMock(http, urls)
            }
            registerService('aiTaggingTour', service)
            return <AiTaggingTour service={service} workspaceUrl={workspaceUrl}  />
        }}
    </WidgetContext.Consumer>
}
