// ref: https://confluence.zhihuiya.com/display/PS/Privilege+Renew+Tips+API

import type { T_UTCTime } from '../../utils/datetime'

export enum E_NoticeCode {
    cp_renew = 'com.patsnap.identity.privilege.tips.cp_renew',
    ap_renew = 'com.patsnap.identity.privilege.tips.ap_renew'
}

export interface IClientParams {
    // 产品客户端Id，必须绑定对应产品ID
    client_id: string
}

export type IGetPrivilegeRenewParams = IClientParams

export interface IPrivilegeRenewItem {
    user_id?: string
    // 匿名时返回
    company_id: string
    product_id: string
    session_id: string
    // 产品对应最长有效的套餐Id	
    company_privilege_id: string
    notice: boolean
    expired_at: T_UTCTime
    latest_notice: T_UTCTime
    tip_code: E_NoticeCode
}

export type IGetPrivilegeRenewData = IPrivilegeRenewItem | null

export type IPutPrivilegeRenewParams = IClientParams
export type IDeletePrivilegeRenewParams = IClientParams
