import { Component } from 'preact'
import { loadScript } from '../../utils/functions';
import _ from 'lodash'
import type { IQiyuConfig } from './type';
import qiyu from './qiyu.new'
import type { QiyuOrZendeskService } from './service'

export interface IQiyuProps {
    qiyu_key: string
    company_name?: string
    email?: string
    mobile_number?: string
    manual_company?: string
    robot_shunt_switch?: string
    service_level?: string
    industry?: string
    account_id: string
    account_type: string
    company_id?: string
    cs_name?: string
    cs_email?: string
    config: IQiyuConfig
    service: QiyuOrZendeskService
    tenant_user_id?: string
}

interface IOption {
    uid: string
    name: string
    email: string
    mobile: string
    level: number
    robotShuntSwitch: number
    data: string
    groupid?: string
}

const E_AccounType = {
    COOPERATIVE: '渠道推广',
    FREE: '免费',
    INTERNAL: '内部',
    NOT_RENEW: '断约',
    STANDARD: '付费(正式)',
    TRIAL: '试用',
}

const E_GroupId = {
    STANDARD: '3977195',
    OTHER: '482875537',
}

const serviceLevelMap = {
    5: 'V3',
    4: 'V2',
    3: 'V1',
    2: 'V0',
    1: '',
}



export class Qiyu extends Component<IQiyuProps> {
    state: {
        loading: true
    }
    get ScriptUrl() {
        const { qiyu_key } = this.props
        return `https://qiyukf.com/script/${qiyu_key}.js`
    }
    componentDidMount() {
        qiyu({
            key: this.props.qiyu_key,
            config: this.props.config,
            option: this.initQiYuOption(),
        })
        // this.loadScript()
    }

    loadScript() {
        this.setState({
            loading: true,
        })
        loadScript(this.ScriptUrl)
            .then(() => {
                window.ysf('onready', () => {
                    this.initQiYuOption()
                })
            })
    }
    getUid() {
        const {
            email,
            mobile_number,
            account_id,
            tenant_user_id,
        } = this.props
        /**
         * uid的作用是标识七鱼中的用户，如果uid发生变化会导致七鱼中用户相关的标识数据丢失
         * Analytics原有的逻辑是，优先邮箱，如果没有邮箱使用DeviceId
         * 所以此处需要做一下兼容，如果没有邮箱，优先使用lastId，没有lastId的时候再使用其他标识
         **/
        const lastId = localStorage.getItem('_qiyukefu_last_dev_id') || '';
        if (tenant_user_id && tenant_user_id !== account_id) {
            return tenant_user_id
        }
        return email || lastId || mobile_number || account_id || this.getDeviceId()
    }
    getDeviceId() {
        const lastId = localStorage.getItem('_qiyukefu_last_dev_id') || '';
        if (lastId) {
            return lastId;
        }
        const id = Date.now() + Math.random().toString()
        localStorage.setItem('_qiyukefu_last_dev_id', id);
        return id;
    }
    getDefaultGroupId(account_type: string) {
        if (account_type === 'STANDARD') {
            return E_GroupId.STANDARD
        }
        return E_GroupId.OTHER

    }

    initQiYuOption() {
        const {
            config,
            company_name,
            email,
            mobile_number,
            manual_company,
            service_level,
            // robot_shunt_switch,
            industry,
            account_id,
            account_type,
            company_id,
            cs_name,
            cs_email,
        } = this.props
        const accountLink = `${this.props.service.getBackofficeUri()}/#/accounts/${account_id}/tenant/${company_id || 'null'}/product`
        const companyLink = `${this.props.service.getBackofficeUri()}/#/companies/${company_id}/products`
        const option: IOption = {
            uid: this.getUid(),
            name: company_name, // 客服故意要求用公司名
            email,
            mobile: mobile_number,
            level: Number(service_level) || 1,
            robotShuntSwitch: 1,
            groupid: this.getDefaultGroupId(account_type),  // 设置默认客服组为售前组
            data: JSON.stringify([
                {
                    index: 0,
                    key: 'Company Name',
                    value: company_name,
                    label: '公司名',
                },
                {
                    index: 1,
                    key: 'Manual Company',
                    value: manual_company,
                    label: '自注册客户名',
                },
                {
                    index: 2,
                    key: 'Industry',
                    value: industry,
                    label: '客户行业',
                },
                {
                    index: 3,
                    key: 'Service Level',
                    value: _.get(serviceLevelMap, service_level),
                    label: '客户等级',
                },
                {
                    index: 4,
                    key: 'CSM',
                    value: cs_name || cs_email,
                    label: '负责CSM',
                },
                {
                    index: 5,
                    key: 'Account Type',
                    value: _.get(E_AccounType, account_type, account_type),
                    label: '账号类型',
                },
                {
                    index: 6,
                    key: 'Account Link',
                    value: account_id ? accountLink : '',
                    href: account_id ? accountLink : '',
                    label: 'BO用户详情链接',
                },
                {
                    index: 7,
                    key: 'Company Link',
                    value: company_id ? companyLink : '',
                    href: company_id ? companyLink : '',
                    label: 'BO公司详情链接',
                }

            ]),
        }
        if (config && config.groupid) {
            option.groupid = config.groupid
        }
        return option
    }
    openQiYu() {
        window.ysf('open')
    }

    render(props: IQiyuProps) {
        return null
    }
}