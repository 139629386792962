import { h } from 'preact'
import { WidgetContext } from '../../context'
import { buildUrls } from './urls'
import { PublishRemindService, PublishRemindServiceMock } from './service'
import { PublichRemind } from './PublichRemind'

export function PublishRemindWrapper() {
    return <WidgetContext.Consumer>
        {context => {
            const {
                accountServerBase,
                http,
                clientId,
                mockPublish,
                debug,
                registerService } = context
            const urls = buildUrls(accountServerBase)
            let service = new PublishRemindService(http, urls, clientId)
            if (mockPublish) {
                service = new PublishRemindServiceMock(http, urls, clientId)
            }
            registerService('publishRemind', service)
            return <PublichRemind service={service} debug={debug} />
        }}
    </WidgetContext.Consumer>
}
