import type { Http } from '../../http'
import type { IUrls } from './urls'
import type {
    IDeletePrivilegeRenewParams,
    IGetPrivilegeRenewData,
    IGetPrivilegeRenewParams,
    IPutPrivilegeRenewParams,
} from './types'
import { TokenService } from '../../services/TokenService'

export class ExpirationRemindService {
    constructor(
        private http: Http,
        private urls: IUrls,
        private client_id?: string) {
    }

    private tokenService: TokenService = new TokenService()

    isAnonymous(data: IGetPrivilegeRenewData) {
        // 没有账号的是匿名用户
        if (!data) {
            return false
        }
        return !data.user_id
    }

    hasAnonymousTip(data: IGetPrivilegeRenewData) {
        const key = this.buildRenewLocaleKey(data)
        const value = localStorage.getItem(key)
        return value === 'true' || value === '1'
    }

    markAnonymousTip(data: IGetPrivilegeRenewData) {
        const key = this.buildRenewLocaleKey(data)
        localStorage.setItem(key, '1')
    }

    private buildRenewLocaleKey(data: IGetPrivilegeRenewData) {
        return `private-renew-tip-${data.company_privilege_id}`
    }

    setPrivilegeRenewToLocal(data: IGetPrivilegeRenewData) {
        const userId = this.getUserId()
        if (userId) {
            localStorage.setItem('COMMON_WIDGET_PRIVATE_RENEW', JSON.stringify({
                userId: this.getUserId(),
                time: Date.now(),
                data,
            }))
        }
    }

    getPrivilegeRenewFromLocal() {
        const privilegeRenew = localStorage.getItem('COMMON_WIDGET_PRIVATE_RENEW')
        if (privilegeRenew) {
            try {
                const privilegeRenewData = JSON.parse(privilegeRenew)
                const userId = this.getUserId()
                if (privilegeRenewData.data.user_id === userId && Date.now() - privilegeRenewData.time < 1000 * 60 * 60 * 24) {
                    return privilegeRenewData.data
                }
            } catch (error) {
                console.log(error)
            }
        }
    }

    getUserId() {
        return this.tokenService.userId
    }

    async getPrivilegeRenew(params?: IGetPrivilegeRenewParams) {
        const data = this.getPrivilegeRenewFromLocal()
        if (data) {
            return data
        }
        if(this.tokenService.isAnonymous || !this.tokenService.isTokenValid) {
            return null
        }
        const result = await this.getPrivilegeRenewData(params)
        this.setPrivilegeRenewToLocal(result)
        return result
    }

    // 获取需要不需要弹窗
    getPrivilegeRenewData(params?: IGetPrivilegeRenewParams) {
        const url = this.urls.getPrivilegeRenew
        return this.http.request<IGetPrivilegeRenewData>(url, {
            params: {
                client_id: this.client_id,
                ...params,
            },
            withCredentials: true,
        }).then(data => {
            if (this.isAnonymous(data) && this.hasAnonymousTip(data)) {
                return null
            }
            return data
        })
    }
    // 清除弹窗
    putPrivilegeRenew(renewData: IGetPrivilegeRenewData, params?: IPutPrivilegeRenewParams) {
        if (!renewData) {
            return Promise.resolve(null)
        }
        if (this.isAnonymous(renewData)) {
            this.markAnonymousTip(renewData)
            return Promise.resolve(null)
        }
        const url = this.urls.putPrivilegeRenew
        return this.http.request(url, {
            params: {
                client_id: this.client_id,
                ...params,
            },
            method: 'put',
            withCredentials: true,
        }).then(data => {
            this.setPrivilegeRenewToLocal({ ...(this.getPrivilegeRenewFromLocal() || {}), notice: false })
            return data
        })
    }
    // 反清除弹窗, 用于给测试使用
    putPrivilegeRevoke(params?: IDeletePrivilegeRenewParams) {
        const url = this.urls.putPrivilegeRevoke
        return this.http.request(url, {
            params: {
                client_id: this.client_id,
                ...params,
            },
            method: 'put',
            withCredentials: true,
        })
    }
}
