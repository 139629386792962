import _ from 'lodash'
interface ITokenData {
    session: string
    sub: string
    tenant_id: string
    exp: number
    iat: number
}

export class TokenService {
    constructor(){
        this.init()
    }

    public tokenData: ITokenData = null

    get session(){
        return _.get(this.tokenData, 'session')
    }

    get userId(){
        return _.get(this.tokenData, 'sub')
    }

    get isTokenValid(){
        return _.get(this.tokenData, 'exp') * 1000 > Date.now()
    }

    get isAnonymous(){
        return _.get(this.tokenData, 'sub') === _.get(this.tokenData, 'tenant_id')
    }

    public init(){
        this.tokenData = this.getTokenData() || {}
    }

    getTokenData(){
        const authorizations = localStorage.getItem('patsnap-authorizations')
        if (authorizations) {
            try {
                const authorizationsData = JSON.parse(authorizations)
                const tokenStr = authorizationsData.token.split('.')[1];
                const tokenData = JSON.parse(window.atob(tokenStr));
                return tokenData
            } catch (error) {
                console.log(error)
            }
        }
    }
}