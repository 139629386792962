import _ from 'lodash';
import type { Http } from '../../http';
import { TokenService } from '../../services/TokenService';
import { format } from '../../utils/string';
import type { IGetCarsiSuspectData } from './types';
import type { IUrls } from './urls';
import { getCookie, setCookie } from '../../utils/cookie';

interface ICacheValue {
    key: string
    value: IGetCarsiSuspectData
    userId: string
    mock?: boolean
}

const StorageKey = 'COMMON_WIDGET_UNAUTHORIZED_LOGIN_REMIND'

export class UnauthorizedLoginRemindService {
    constructor(
        private http: Http,
        private urls: IUrls
    ) {
    }

    tokenService = new TokenService()


    setCookie(storageData) {
        setCookie(StorageKey, storageData)
    }

    getCookie() {
        return getCookie(StorageKey)
    }

    /**
     * 获取是否发送请求的key
     */
    getCheckUpdateKey() {
        const now = new Date()
        return `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`
    }

    getCacheValue() {
        const value = this.getCookie()
        if (!value) {
            return {}
        }
        try {
            const data = JSON.parse(value) as ICacheValue
            if (_.isObject(data)) {
                if (data.key === this.getCheckUpdateKey() && data.userId === this.tokenService.userId) {
                    return data
                }
            }
        } catch (e) {
            return {}
        }
        return {}
    }

    storeCacheValue(data: IGetCarsiSuspectData) {
        try {
            const storageData: ICacheValue = {
                key: this.getCheckUpdateKey(),
                value: data,
                userId: data.tenant_user_id,
            }
            this.setCookie(JSON.stringify(storageData))
        } catch (e) {
            // ignore
        }
    }

    getCarsiSuspect() {
        const tenantUserId = this.tokenService.userId
        const url = format(this.urls.getCarsiSuspect, { tenantUserId })
        return this.http.request<IGetCarsiSuspectData>(url, {
            method: 'get',
            withCredentials: false,
        })
    }

    queryCarsiSuspect() {
        const cacheObj = this.getCacheValue() as ICacheValue

        const carsiSuspectData = cacheObj.value
        if (carsiSuspectData !== undefined && !carsiSuspectData?.suspect_sell) {
            return Promise.resolve(carsiSuspectData)
        }
        if (this.tokenService.isAnonymous || !this.tokenService.isTokenValid) {
            return Promise.resolve({
                suspect_sell: false,
                can_close: false,
            })
        }
        return this.getCarsiSuspect().then(data => {
            this.storeCacheValue(data)
            return data
        })
    }

    /**
     * 仅仅当前不再提醒，不更新缓存时间
     */
    closeRemind() {
        const cacheObj = this.getCacheValue() as ICacheValue
        cacheObj.value.suspect_sell = false
        this.setCookie(JSON.stringify(cacheObj))
    }
}

export class UnauthorizedLoginRemindServiceMock extends UnauthorizedLoginRemindService {

    getCarsiSuspect() {
        console.log('UnauthorizedLoginRemindServiceMock.getCarsiSuspect')
        return Promise.resolve({
            tenant_user_id: '7bb4bb620ec64d6388fb73f7fdc23eb7',
            suspect_sell: true,
            can_close: true,
        } as any)
    }
}