import { h } from 'preact'
import { WidgetContext } from '../../context'
import { MultiTenantGuideRemind } from './MultiTenantGuideRemind'
import { MultiTenantGuideRemindService, MultiTenantGuideRemindServiceMock } from './service'
// import { ExpirationRemindServiceMock } from './__mock__/service.mock'
import './style.scss'
import { buildUrls } from './urls'

export function MultiTenantGuideRemindWrapper() {
    return <WidgetContext.Consumer>
        {context => {
            const {
                accountServerBase,
                http,
                clientId,
                from,
                mockMultiTenantGuide,
                serverBase,
                debug,
                registerService } = context
            const urls = buildUrls({ accountServerBase, serverBase })
            let service = new MultiTenantGuideRemindService(http, urls, clientId, from)
            if (mockMultiTenantGuide) {
                service = new MultiTenantGuideRemindServiceMock(http, urls, clientId, from)
            }
            registerService('multiTenantGuide', service)
            return <MultiTenantGuideRemind service={service} debug={debug} />
        }}
    </WidgetContext.Consumer>
}
