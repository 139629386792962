import { h } from 'preact'
import { WidgetContext } from '../../context'
import { QiyuOrZendesk } from './QiyuOrZendesk'
import { QiyuOrZendeskService } from './service'
// import { ExpirationRemindServiceMock } from './__mock__/service.mock'
// import './style.scss'
import { buildUrls } from './urls'

export function QiyuOrZendeskWrapper() {
    return <WidgetContext.Consumer>
        {context => {
            const {
                accountServerBase,
                backofficeUri,
                http,
                // mockLegalTermsUpdate,
                qiyuConfig,
                zendeskConfig,
                debug,
                registerService } = context
            const urls = buildUrls({ accountServerBase, backofficeUri })
            const service = new QiyuOrZendeskService(http, urls, qiyuConfig, zendeskConfig)
            // if (mockLegalTermsUpdate) {
            //     service = new QiyuOrZendeskServiceMock(http, urls, qiyuConfig, zendeskConfig)
            // }
            registerService('expiration', service)
            return <QiyuOrZendesk service={service} debug={debug} />
        }}
    </WidgetContext.Consumer>
}
