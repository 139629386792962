import _ from 'lodash'
import type { Http } from '../../http'
import { getCookie } from '../../utils/cookie'
import { E_Region } from '../../utils/environment'
import type { IGetQueryLegalTermsUpdateData } from './types'
import type { IUrls } from './urls'
import { TokenService } from '../../services/TokenService'

interface ICacheValue {
    key: string
    value: any
    userId: string
    mock?: boolean
    region?: E_Region
}

const StorageKey = 'query-legal-terms-update'

export class LegalTermsUpdateRemindService {
    constructor(
        private http: Http,
        private urls: IUrls,
        private from: string,
        private client_id?: string
    ) {
    }

    private tokenService: TokenService = new TokenService()

    region: E_Region = null

    get IsRegionCN() {
        return _.toUpper(this.region) === E_Region.CN
    }

    get TermsOfService() {
        if (this.IsRegionCN) {
            return 'https://www.zhihuiya.com/legal/terms';
        }
        return 'https://www.patsnap.com/legal/general-patsnap-services-terms-and-conditions-current/';
    }

    get PrivacyPolicy() {
        if (this.IsRegionCN) {
            return 'https://www.zhihuiya.com/legal/privacy';
        }
        return 'https://www.patsnap.com/legal/privacy-policy';
    }

    getCurrentUserId() {
        return getCookie('ajs_user_id')
    }

    /**
     * 获取是否发送请求的key
     */
    getCheckUpdateKey() {
        const now = new Date()
        return `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`
    }

    getCacheValue() {
        const value = localStorage.getItem(StorageKey)
        if (!value) {
            return {}
        }
        try {
            const data = JSON.parse(value) as ICacheValue
            if (_.isObject(data)) {
                if (data.key === this.getCheckUpdateKey() && data.userId === this.getCurrentUserId()) {
                    return data
                }
            }
        } catch (e) {
            return {}
        }
        return {}
    }

    storeCacheValue(data: IGetQueryLegalTermsUpdateData) {
        try {
            const storageData: ICacheValue = {
                key: this.getCheckUpdateKey(),
                value: data.terms_version_change,
                userId: this.getCurrentUserId(),
                region: data.region,
            }
            localStorage.setItem(StorageKey, JSON.stringify(storageData))
        } catch (e) {
            // ignore
        }
    }

    getQueryLegalTermsUpdate() {
        const cacheObj = this.getCacheValue() as ICacheValue
        if (cacheObj?.mock) {
            this.region = cacheObj.region
            return Promise.resolve(cacheObj.value)
        }

        const termsUpdateValue = cacheObj.value
        if (termsUpdateValue !== undefined && !termsUpdateValue) {
            this.region = cacheObj.region
            return Promise.resolve(termsUpdateValue)
        }
        if(this.tokenService.isAnonymous || !this.tokenService.isTokenValid){
            return Promise.resolve()
        }
        const url = this.urls.getQueryLegalTermsUpdate
        return this.http.request<IGetQueryLegalTermsUpdateData>(url, {
            method: 'get',
            withCredentials: false,
        }).then(data => {
            this.storeCacheValue(data)
            this.region = data.region
            return data.terms_version_change
        })
    }

    putQueryLegalTermsUpdate() {
        const url = this.urls.putLegalTermsUpdate
        return this.http.request(url, {
            method: 'put',
            withCredentials: false,
        }).then(data => {
            this.storeCacheValue('' as any)
        })
    }

    handleLogout() {
        //登出后需要移除缓存 下次登录用户会更新
        localStorage.removeItem(StorageKey)
        window.location.href = `${this.urls.getLogout}?redirect_uri=${encodeURIComponent(window.location.href)}`
    }
}

export class LegalTermsUpdateRemindServiceMock extends LegalTermsUpdateRemindService {
    getQueryLegalTermsUpdate() {
        const cacheObj = this.getCacheValue() as ICacheValue
        const termsUpdateValue = cacheObj.value

        if (termsUpdateValue !== undefined && !termsUpdateValue) {
            this.region = cacheObj.region
            return Promise.resolve(termsUpdateValue)
        }
        return Promise.resolve({
            terms_version_change: true,
            region: E_Region.CN,
        }).then((data) => {
            this.storeCacheValue(data)
            this.region = data.region
            return data.terms_version_change
        })
    }
}