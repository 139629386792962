import { h, Component } from 'preact'
import type { AiTaggingTourService } from './service'
import _ from 'lodash'
import './style.scss'
import CloseIcon from '../../assets/close.svg'
import { Icon } from '../../components/Icon'
import { Translation } from '../../preact-i18n'
import type { I_AiTaggingTourStatus } from './type'
import qs from 'qs'

interface AiTaggingTourProps {
    service: AiTaggingTourService
    workspaceUrl: string
    debug?: boolean
}

export class AiTaggingTour extends Component<AiTaggingTourProps> {
    state:I_AiTaggingTourStatus = {
        show_ai_tagging_tour: false,
    }

    get isInTourProcess() { 
        const query = qs.parse(window.location.search, { ignoreQueryPrefix: true })
        return _.has(query, 'ai_tagging_tour_status')
    }
    
    closeTourDialog=()=> {
        const { service } = this.props
        this.setState({
            show_ai_tagging_tour: false,
        })
        service.closeAiTaggingTour()
    }

    goToSpace=() =>{
        const { workspaceUrl } = this.props
        const url = `${workspaceUrl}?ai_tagging_tour_status=process`
        window.open(url, '_blank')
        this.closeTourDialog()
    }

    componentWillMount() {
        const { service } = this.props
        // 如果是在引导过程中，直接关闭引导，并标识为已查看
        if (this.isInTourProcess) {
            service.closeAiTaggingTour()
            return
        }
        service.getAiTaggingTourStatus().then((res) => {
            this.setState({
                ...res,
            })
        }).catch((err) => {
            console.error(err)
        })
    }

    render() {
        const { show_ai_tagging_tour } = this.state

        if (!show_ai_tagging_tour) {
            return null
        }

        return (
            <Translation>
                {
                    (t, { locale }) => {
                        const imgSrc = require(`../../assets/ai-tagging-tour/${locale.toLowerCase()}_end.png`).default
                        return (
                            <div className="widget__ai-tagging-tour-container">
                                <div className="widget__ai-tagging-tour-dialog">
                                    <div className="widget__ai-tagging-tour-close">
                                        <Icon glyph={CloseIcon.id} onClick={this.closeTourDialog} />
                                    </div>
                                    <div className="widget__ai-tagging-tour-img"><img width="100%" src={imgSrc} /></div>
                                    <div className="widget__ai-tagging-tour-desc">{t('aiTaggingTour.desc')}</div>
                                    <div className="widget__ai-tagging-tour-button">
                                        <button onClick={this.goToSpace}>{ t('aiTaggingTour.button') }</button>
                                    </div>
                                </div>
                            </div>)
                    }
                }
            </Translation>
        )
    }
}