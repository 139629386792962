import qs from 'qs'
import _ from 'lodash'
import type { T_Theme } from '../types'

export enum E_Region {
    CN = 'CN',
    EU = 'EU',
    US = 'US'
}

export function getVar(key: string, type: ('string' | 'boolean') = 'string') {
    // todo!!!
    const query = qs.parse(window.location.search, { ignoreQueryPrefix: true })
    if (_.has(query, key)) {
        const value = _.get(query, key)
        if (_.isNil(value)) {
            return true
        }
        if (type === 'boolean') {
            if (_.toLower(value as any) === 'false') {
                return false
            }
            return true

        }
        return value
    }
    return localStorage.getItem(key)
}

export const IS_DEBUG = getVar('fe_debug', 'boolean') as boolean

export function guessRegion(hostname?: string) {
    hostname = hostname || window.location.hostname
    if (hostname === '127.0.0.1' || hostname === 'localhost') {
        return E_Region.CN
    }
    //如果域名是zhihuiya.com 算中国
    if (/zhihuiya.com/.test(hostname)) {
        return E_Region.CN
    }
    return 'qa'.toUpperCase()
}


export function guessTheme(hostname?: string): T_Theme {
    hostname = hostname || window.location.hostname
    if (/eureka/.test(hostname)) {
        return 'blue'
    }
    return 'green'

}

export function guessFrom() {
    return window.process?.env?.VUE_APP_PASSPORT_CLIENT_NAME || ''
}

export function guessConfig(hostname?: string) {
    hostname = hostname || window.location.hostname
    if (hostname === '127.0.0.1' || hostname === 'localhost') {
        return {
            accountServerBase: 'https://qa-account-service.zhihuiya.com',
            labServerBase: 'https://qa-lab-service.zhihuiya.com',
            anchor_id: 'JpDMLVcqqD2PCj-A4aW0I',
            backofficeUri: 'https://qa-backoffice.zhihuiya.com',
            workspaceUrl: 'https://qa-workspace.zhihuiya.com',
            workspaceServerBase: 'https://qa-workspace-service.zhihuiya.com',
            env: 'qa',
        }
    }
    return {
        accountServerBase: 'https://qa-account-service.zhihuiya.com',
        labServerBase: 'https://qa-lab-service.zhihuiya.com',
        anchor_id: '{{TRACELESS_BROWSING_ANCHOR}}',
        backofficeUri: 'https://qa-backoffice.zhihuiya.com',
        workspaceUrl: 'https://qa-workspace.zhihuiya.com',
        workspaceServerBase: 'https://qa-workspace-service.zhihuiya.com',
        env: 'qa',
    }
}

export function guessEnv(hostname?: string) {
    return guessConfig(hostname).env
}
