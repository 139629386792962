import { h } from 'preact'
import { WidgetContext } from '../../context'
import { ExpirationRemind } from './ExpirationRemind'
import { ExpirationRemindService } from './service'
import { ExpirationRemindServiceMock } from './__mock__/service.mock'
import './style.scss'
import { buildUrls } from './urls'

export function ExpirationRemindWrapper() {
    return <WidgetContext.Consumer>
        {context => {
            const { serverBase, http, clientId, mockExpiration, debug, registerService } = context
            const urls = buildUrls(serverBase)
            let service = new ExpirationRemindService(http, urls, clientId)
            if (mockExpiration) {
                service = new ExpirationRemindServiceMock(http, urls, clientId)
            }
            registerService('expiration', service)
            return <ExpirationRemind service={service} debug={debug} />
        }}
    </WidgetContext.Consumer>
}
