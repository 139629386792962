import _ from 'lodash'
import type {
    ComponentChildren,
    VNode,
} from 'preact';
import {
    h,
    createContext,
    Component,
    toChildArray,
} from 'preact'
import type { I18n } from './mini-i18n'

interface I18nContextData {
    i18n: I18n
}

export const I18nContext = createContext<I18nContextData>(null)

type ITransFn = (key: string, obj?: object) => string
interface ITranslationParamsEx {
    i18n: I18n
    locale: string
    dayjs_locale: string
    join: (items: any[]) => string
}

type WithTransContextChildren = (t: ITransFn, paramsEx: ITranslationParamsEx) => VNode<any>

interface ITranslationProps {
    children: WithTransContextChildren | ComponentChildren
}

export class Translation extends Component<ITranslationProps> {
    render() {
        return <I18nContext.Consumer>
            {context => toChildArray(this.props.children).map(child => {
                if (_.isFunction(child)) {
                    return (child as any)(context.i18n.t, {
                        i18n: context.i18n,
                        locale: context.i18n.locale,
                        dayjs_locale: context.i18n.dayjs_locale,
                        join: context.i18n.join,
                    })
                } 
                return child
                
            })}
        </I18nContext.Consumer>
    }
}
