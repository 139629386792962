export function noop() { }


export function loadScript(url: string, attrs = {}) {
    return new Promise((resolve, reject) => {
        // Adding the script tag to the head as suggested before
        const head = document.getElementsByTagName('head')[0];
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.async = true;
        script.charset = 'UTF-8';
        for (const attr in attrs) {
            script.setAttribute(attr, attrs[attr]);
        }

        script.onload = resolve;
        script.onerror = reject;

        // Fire the loading
        head.appendChild(script);
    });
}

export function isInIframe() {
    try {
        return window.self !== window.top;
    } catch (e) {
        return false;
    }
}
