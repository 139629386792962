import type { Http } from '../../http'
import type { IUrls } from './urls'
import type {
    IGetQueryReleasePlanData,
} from './types'
import _ from 'lodash'
import { getCookie } from '../../utils/cookie'
import { TokenService } from '../../services/TokenService'

const StorageKey = 'query-release-plan'

interface ICacheValue {
    key: string
    value: IGetQueryReleasePlanData
    userId: string
}

export class PublishRemindService {
    constructor(
        private http: Http,
        private urls: IUrls,
        private client_id?: string) {
    }
    
    private tokenService: TokenService = new TokenService()

    getCurrentUserId() {
        return getCookie('ajs_user_id')
    }

    /**
     * 获取是否发送请求的key
     */
    getCheckUpdateKey() {
        const now = new Date()
        return `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`
    }

    getCacheValue() {
        const value = localStorage.getItem(StorageKey)
        if (!value) {
            return undefined
        }
        try {
            const data = JSON.parse(value) as ICacheValue
            if (_.isObject(data)) {
                if (data.key === this.getCheckUpdateKey() && data.userId === this.getCurrentUserId()) {
                    return data.value
                }
            }
        } catch (e) {
            return undefined
        }
        return undefined
    }

    storeCacheValue(data: IGetQueryReleasePlanData) {
        try {
            const storageData: ICacheValue = {
                key: this.getCheckUpdateKey(),
                value: data,
                userId: this.getCurrentUserId(),
            }
            localStorage.setItem(StorageKey, JSON.stringify(storageData))
        } catch (e) {
            // ignore
        }
    }

    /**
     * 用户查询是否需要发版提示
     */
    getQueryReleasePlan() {
        const value = this.getCacheValue()
        if (value !== undefined) {
            return Promise.resolve(value)
        }

        if(this.tokenService.isAnonymous || !this.tokenService.isTokenValid){
            return Promise.resolve()
        }
        const url = this.urls.getQueryReleasePlan
        return this.http.request<IGetQueryReleasePlanData>(url, {
            method: 'get',
            withCredentials: false,
        }).then(data => {
            this.storeCacheValue(data)
            return data
        })
    }
    postCloseReleasePlan() {
        const url = this.urls.postCloseReleasePlan
        return this.http.request(url, {
            method: 'post',
            withCredentials: false,
        }).then(data => {
            this.storeCacheValue('' as any)
        })
    }
    postReopenReleasePlan() {
        const url = this.urls.postReopenReleasePlan
        return this.http.request(url, {
            method: 'post',
            withCredentials: false,
        }).then(data => {
            localStorage.removeItem(StorageKey)
        })
    }
}

export class PublishRemindServiceMock extends PublishRemindService {
    getQueryReleasePlan() {
        return Promise.resolve({
            release_date: '2022-04-29T10:57:25.000Z',
            begin_at: '2022-04-29T10:57:25.000Z',
            end_at: '2022-04-29T10:57:25.000Z',
            display_products: [
                'pro'
            ],
        })
    }
}
