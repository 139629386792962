import classNames from 'classnames'
import type { FunctionComponent} from 'preact';
import { h } from 'preact'

import './AlertGroup.scss'

interface IAlertGroupProps {
    location?: 'bottom-center' | 'fixed-top-center'
    [key: string]: any
}

export const AlertGroup: FunctionComponent<IAlertGroupProps> = ({ children, location, ...rest }) => {
    const classNameUse = classNames('pt-widget-alerts-group', location ? `pt-widget-alerts-group--${location}` : null)
    return (
        <div class="pt-widget-alerts-group-wrapper">
            <div className={classNameUse} {...rest}>
                {children}
            </div>
        </div>)
}
AlertGroup.displayName = 'AlertGroup'
