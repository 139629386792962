import { h, Component } from 'preact'
import { isInIframe } from '../../utils/functions'
import type { IQiyuProps} from './Qiyu';
import { Qiyu } from './Qiyu'
import type { QiyuOrZendeskService } from './service'
import type { IZendeskProps} from './Zendesk';
import { Zendesk } from './Zendesk'
import _ from 'lodash'
import './style.scss'

interface IQiyuOrZendeskProps {
    service: QiyuOrZendeskService
    debug?: boolean
}

export class QiyuOrZendesk extends Component<IQiyuOrZendeskProps> {
    state = {
        loading: false,
        qiyu: false,
        zendesk: false,
        qiyuProps: null as IQiyuProps,
        zendeskProps: null as IZendeskProps,
    }

    componentWillMount() {
        const { service } = this.props
        this.setState({
            loading: true,
        })
        service.getInfo().then((res) => {
            if(res.qiyuOrZendeskInfo?.qiyu_key) {
                this.setState({
                    qiyu: true,
                    qiyuProps: service.getQiyuProps(res.qiyuOrZendeskInfo, res.baseInfo),
                })
            }
            if(res.qiyuOrZendeskInfo?.zendesk_key) {
                this.setState({
                    zendesk: true,
                    zendeskProps: service.getZendeskProps(res.qiyuOrZendeskInfo, res.baseInfo),
                })
            }
        }).finally(() => {
            this.setState({
                loading: false,
            })
        })
    }

    render(props: IQiyuOrZendeskProps) {
        const { service } = props
        if (isInIframe()) {
            return;
        }
        if (this.state.loading) {
            return;
        }
        if (this.state.qiyu && this.state.qiyuProps) {
            if (_.includes(service.QiyuConfig?.blacklistPaths, window.location.pathname)) {
                return;
            }
            return (<Qiyu {...this.state.qiyuProps} service={service} />)
        }
        if (this.state.zendesk && this.state.zendeskProps) {
            if (_.includes(service.ZendeskConfig?.blacklistPaths, window.location.pathname)) {
                return;
            }
            return (<Zendesk {...this.state.zendeskProps} />)
        }
        return null
    }
}