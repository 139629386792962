import { h, Component } from 'preact'
import { Translation } from '../../preact-i18n'
import { Msgbox } from '../../components/MsgBox'
import type { UnauthorizedLoginRemindService } from './UnauthorizedLoginRemindService'
import './style.scss'
import type { IGetCarsiSuspectData } from './types'

interface IUnauthorizedLoginRemindProps {
    service: UnauthorizedLoginRemindService
    debug?: boolean
}

export class UnauthorizedLoginRemind extends Component<IUnauthorizedLoginRemindProps> {
    state = {
        loading: false,
        error: false,
        data: null as IGetCarsiSuspectData,
        closed: false,
        confirmBtnVisible: true,
    }

    private setError = (error) => {
        const {
            debug,
        } = this.props
        if (debug) {
            console.error('UnauthorizedLoginRemind:', error)
        }
        this.setState({
            error,
        })
    }

    componentWillMount() {
        const { service } = this.props
        this.setState({
            loading: true,
        })
        service.queryCarsiSuspect().then(data => {
            if (!data) {
                return
            }

            this.setState({
                data,
                confirmBtnVisible: data.can_close,
            })
        }, this.setError).finally(() => {
            this.setState({
                loading: false,
            })
        })
    }

    onConfirm = () => {
        const {
            service,
        } = this.props
        this.setState({
            closed: true,
        })
        service.closeRemind()
    }

    render(props: IUnauthorizedLoginRemindProps) {
        const {
            error,
            loading,
            data,
            closed,
            confirmBtnVisible,
        } = this.state
        if (closed) {
            return null
        }
        if (!loading && !error && data.suspect_sell) {
            return (
                <Translation>
                    {(t) => {
                        const msg = t('unauthorizedLoginRemind.content')
                        return <Msgbox
                            title={t('unauthorizedLoginRemind.title')}
                            className='unauthorized-login-remind'
                            message={msg}
                            hiddenCancel={true}
                            hiddenConfirm={!confirmBtnVisible}
                            confirmButtonText={t('common.gotIt')}
                            cancelButtonText="Cancel"
                            onConfirm={this.onConfirm}
                        />
                    }}
                </Translation>
            )
        }
        return null

    }
}


