import _ from 'lodash'

const TEMPLATE_CACHE = {}

export function format(template_str: string, params: object) {
    let fn
    if (_.has(TEMPLATE_CACHE, template_str)) {
        fn = TEMPLATE_CACHE[template_str]
    } else {
        fn = _.template(template_str)
        // @ts-ignore
        TEMPLATE_CACHE[template_str] = fn
    }
    return fn(params)
}
