/* eslint-disable prefer-rest-params */
import _ from 'lodash';

const ZENDESK_CLASSNAMES = {
    HIDDEN: 'zendesk_new_hidden',
    BOTTOM_36PX: 'zendesk_new_bottom_36px',
    RIGHT_20PX: 'zendesk_new_right_20px',
};

let blacklistPaths = [];
let blacklistHashes = [];

(function(history) {
    const pushState = history.pushState;
    history.pushState = function(state) {
        if (typeof history.onpushstate === 'function') {
            history.onpushstate({
                state,
            });
        }
        return pushState.apply(history, arguments);
    };
    const replaceState = history.replaceState;
    history.replaceState = function(state) {
        if (typeof history.onreplacestate === 'function') {
            history.onreplacestate({
                state,
            });
        }
        return replaceState.apply(history, arguments);
    };
})(window.history);

function loadScript(url, attrs = {}) {
    return new Promise((resolve, reject) => {
        // Adding the script tag to the head as suggested before
        const head = document.getElementsByTagName('head')[0];
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.async = true;
        script.charset = 'UTF-8';
        for (const attr in attrs) {
            script.setAttribute(attr, attrs[attr]);
        }

        script.onload = resolve;
        script.onerror = reject;

        // Fire the loading
        head.appendChild(script);
    });
}

function isInIframe() {
    try {
        return window.self !== window.top;
    } catch (e) {
        return false;
    }
}

function debounce(fn, delay) {
    const args = arguments;
    const context = this;
    let timer = null;
    return function() {
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            fn.apply(context, args);
        }, delay);
    };
}

function addCloseButton() {
    const zendeskDom = document.getElementById('launcher');
    if (zendeskDom) {
        zendeskDom.style.height = '50px';

        const zendeskIframeDom = zendeskDom.contentDocument || document.frames.launcher.document;
        if (zendeskIframeDom.querySelector('.launcher-close-button')) {
            return window.clearInterval(window.zendesk_interval_timer);
        }
        const css = `
            button.Arrange {
                transform: scale(0.75) !important;
            }
            html:hover .launcher-close-button{
                display: block!important;
            }
        `;
        const head = zendeskIframeDom.head || zendeskIframeDom.getElementsByTagName('head')[0];
        const style = zendeskIframeDom.createElement('style');
        style.type = 'text/css';
        if (style.styleSheet) {
            // This is required for IE8 and below.
            style.styleSheet.cssText = css;
        } else {
            style.appendChild(document.createTextNode(css));
        }
        head.appendChild(style);

        const colseButton = document.createElement('div');
        colseButton.innerHTML = 'x';
        colseButton.setAttribute('class', 'launcher-close-button');
        colseButton.setAttribute('title', 'Close');
        colseButton.style.cssText = `
            display: none;
            position: absolute;
            width: 1rem;
            height: 1rem;
            border-radius: 50%;
            border: 1px solid rgb(127, 192, 46);
            z-index: 999999;
            cursor: pointer;
            color: rgb(127, 192, 46);
            text-align: center;
            line-height: 0.75rem;
            font-size: 0.8rem;
            right: 0.1rem;
            top: 0.5rem;
        `;
        colseButton.onclick = function(env) {
            zendeskDom.parentNode.removeChild(zendeskDom);
        };
        zendeskIframeDom.body.appendChild(colseButton);
    }
}

function updateClassname() {
    if (!window.zendesk_interval_timer) {
        window.zendesk_interval_timer = setInterval(addCloseButton, 20);
    }
    const ZENDESK_CLASSNAMES_VALS = Object.values(ZENDESK_CLASSNAMES);
    const filterd = blacklistHashes.filter((item) => {
        return window.location.pathname.indexOf(item.path) === 0 && window.location.hash.indexOf(item.hash) === 0;
    });
    const removeClass = ZENDESK_CLASSNAMES_VALS.join(' ');
    const $body = document.querySelectorAll('body')[0];
    if ($body.classList) {
        $body.classList.remove(...ZENDESK_CLASSNAMES_VALS);
    } else {
        $body.className = $body.className.replace(new RegExp(`(^|\\b)${removeClass.split(' ').join('|')}(\\b|$)`, 'gi'), ' ');
    }
    if (filterd && filterd.length && filterd[0] && filterd[0].classes.length) {
        const addClass = filterd[0].classes;
        if ($body.classList) {
            $body.classList.add(addClass);
        } else {
            $body.className += ` ${addClass.join(' ')}`;
        }
    }
}

function appendCss() {
    const css = `
  body.${ZENDESK_CLASSNAMES.HIDDEN} #launcher{
    display: none;
  }
  body.${ZENDESK_CLASSNAMES.BOTTOM_36PX} #launcher{
    bottom: 36px !important;
  }
  body.${ZENDESK_CLASSNAMES.RIGHT_20PX} #launcher{
    right: 20px !important;
    height: 42px !important;
  }
  #launcher{
  }
  iframe#launcher {
    bottom: 40px !important;
  }
  `;
    const head = document.head || document.getElementsByTagName('head')[0];
    const style = document.createElement('style');

    style.type = 'text/css';
    if (style.styleSheet) {
        // This is required for IE8 and below.
        style.styleSheet.cssText = css;
    } else {
        style.appendChild(document.createTextNode(css));
    }

    head.appendChild(style);
}

/* eslint no-unused-vars: "off" */
export default function(params) {

    const ZENDESK_NEW_KEY =
        params.ZENDESK_KEY

    blacklistPaths = _.get(params.config, 'blacklistPaths', []);
    blacklistHashes = _.get(params.config, 'blacklistHashes', []);

    if (_.includes(blacklistPaths, window.location.pathname)) {
        return;
    }

    if (window.zendesk_new_inited) {
        return;
    }

    window.zendesk_new_inited = true;
    if (isInIframe()) {
        return;
    }
    const debounceUpdateClassname = debounce(updateClassname, 20);

    loadScript(`https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_NEW_KEY}`, {
        id: 'ze-snippet',
    }).then(() => {
        appendCss();
        debounceUpdateClassname();

        const popstate = window.onpopstate;
        window.onpopstate = (...args) => {
            debounceUpdateClassname(...args);
            if (typeof popstate === 'function') {
                popstate(...args);
            }
        };

        const hashchange = window.onhashchange;
        window.onhashchange = (...args) => {
            debounceUpdateClassname(...args);
            if (typeof hashchange === 'function') {
                hashchange(...args);
            }
        };

        history.onpushstate = debounceUpdateClassname;
        history.onreplacestate = debounceUpdateClassname;
    });
}