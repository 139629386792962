import { h, Component } from 'preact'
import { Icon } from '../../../components/Icon'

import './BrowserDownload.scss'

interface IBrowserDownloadProps {
    iconGlyph: string
    text: string
    url: string
    onClick: (e: MouseEvent, url: string) => void
}

export class BrowserDownload extends Component<IBrowserDownloadProps> {
    onClick = (e: MouseEvent) => {
        this.props.onClick(e, this.props.url)
    }
    render(props: IBrowserDownloadProps) {
        const {
            iconGlyph,
            text,
            url,
        } = props
        return <a href={url} className='pt-browser-download' onClick={this.onClick}>
            <Icon glyph={iconGlyph} data-icon="browser-icon" />
            {text}
        </a>
    }
}
