import { h, Component } from 'preact'

import { Translation } from '../../preact-i18n'
import { BrowserDownload } from './components/BrowserDownload'
import type { BrowserUpdateRemindService} from './service';
import { E_BrowserState } from './service'
import type { LabService } from '../../services/LabService'

import './BrowserUpdateRemind.scss'

import Chrome from '../../assets/browser/chrome.svg'
import Firefox from '../../assets/browser/firefox.svg'
import Edge from '../../assets/browser/edge.svg'

interface IBrowserUpdateRemindProps {
    service: BrowserUpdateRemindService
    labService: LabService
}

export class BrowserUpdateRemind extends Component<IBrowserUpdateRemindProps> {
    state = {
        closed: false,
        browserState: E_BrowserState.None,
        shouldShowTip: false,
    }
    componentWillMount() {
        const service = this.props.service
        const browserState = service.browserState()
        const shouldShowTip = service.shouldShowTip()
        this.setState({
            browserState,
            shouldShowTip,
        })
    }

    onClose = () => {
        const {
            service,
            labService,
        } = this.props
        service.markShowTip()
        labService.postAppLog({
            action: 'ie_confirm',            
        }).catch(err => {
            console.log(err)
        })
        this.setState({
            closed: true,
        })
    }

    onClickUpdate = (e: MouseEvent, url: string) => {
        e.preventDefault()
        const {
            service,
        } = this.props
        this.setState({
            closed: true,
        })
        service.tryBrowser(url)
    }

    render(props: IBrowserUpdateRemindProps) {
        const {
            browserState,
            shouldShowTip,
            closed,
        } = this.state
        if (closed) {
            return null
        }
        if (browserState === E_BrowserState.None || !shouldShowTip) {
            return null
        }
        const {
            edgeUrl,
            firefoxUrl,
            chromeUrl,
        } = props.service.config
        return (
            <Translation>
                {(t) => {
                    return <div className='browser-update-remaind_wrapper'>
                        <div className='browser-update-remaind'>
                            <div class='browser-update-remaind_title'>
                                {t('ieUpdateTip')}
                            </div>
                            <div className='browser-update-remaind_browsers'>
                                <BrowserDownload iconGlyph={Chrome.id} text={t('downloadChrome')} url={chromeUrl} onClick={this.onClickUpdate} />
                                <BrowserDownload iconGlyph={Edge.id} text={t('downloadEdge')} url={edgeUrl} onClick={this.onClickUpdate} />
                                <BrowserDownload iconGlyph={Firefox.id} text={t('downloadFirefox')} url={firefoxUrl} onClick={this.onClickUpdate} />
                            </div>

                            <div className='browser-update-remaind_footer'>
                                <button className='browser-update-remaind_submit' onClick={this.onClose}>{t('browserRemind.comfirm')}</button>
                            </div>
                        </div>
                    </div>
                }}
            </Translation>
        )
    }
}
