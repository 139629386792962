import _ from 'lodash'
import { E_Region, guessRegion } from '../../utils/environment'

export enum E_BrowserState {
    IE_Expire = 'IE_Expire',
    None = 'None'
}

export interface IBrowserUpdateRemindConfig {
    region?: string
    chromeUrl?: string
    firefoxUrl?: string
    edgeUrl?: string
    disableOpenUrl?: boolean
    forceShow?: boolean
}

const browserUrlDefault = {
    cn: {
        chrome: 'https://www.google.cn/chrome/',
        firefox: 'http://www.firefox.com.cn/',
        edge: 'https://www.microsoft.com/edge',
    },
    other: {
        chrome: 'https://www.google.com/chrome/',
        firefox: 'https://www.mozilla.org/',
        edge: 'https://www.microsoft.com/edge',
    },
}

const IE_TIP_CLOSED_TIME = 'IE_TIP_CLOSED_TIME'

function currentDate() {
    const d = new Date()
    const year = d.getFullYear()
    const month = d.getMonth() + 1
    const date = d.getDate()
    return `${year}-${month}-${date}`
}

export class BrowserUpdateRemindService {
    config: IBrowserUpdateRemindConfig
    constructor(config?: IBrowserUpdateRemindConfig) {
        this.config = this.normalizeConfig(config)
    }

    private defaultUrls(region: string) {
        let urls = browserUrlDefault.other
        if (region === E_Region.CN) {
            urls = browserUrlDefault.cn
        }
        return {
            chromeUrl: urls.chrome,
            firefoxUrl: urls.firefox,
            edgeUrl: urls.edge,
        }
    }

    private normalizeConfig(config?: IBrowserUpdateRemindConfig) {
        if (!config) {
            config = {}
        }
        if (!config.region) {
            config.region = guessRegion()
        }

        config = _.merge(this.defaultUrls(config.region), config)
        return config
    }

    tryBrowser(browserUrl: string) {
        const {
            disableOpenUrl,
        } = this.config
        if (disableOpenUrl) {
            console.warn('redirectTo:', browserUrl)
            return
        }
        window.open(browserUrl)
    }

    browserState() {
        if (this.forceShow()) {
            return E_BrowserState.IE_Expire
        }
        const userAgent = navigator.userAgent
        const ieReg = /msie|trident/i
        if (ieReg.test(userAgent)) {
            return E_BrowserState.IE_Expire
        }
        return E_BrowserState.None
    }

    forceShow() {
        return this.config.forceShow
    }

    shouldShowTip() {
        if (this.forceShow()) {
            return true
        }
        // 每天弹出一次
        const date = localStorage.getItem(IE_TIP_CLOSED_TIME)
        if (date != currentDate()) {
            return true
        }
        return false
    }

    markShowTip() {
        const dateString = currentDate()
        return localStorage.setItem(IE_TIP_CLOSED_TIME, dateString)
    }
}
