import classNames from 'classnames'
import { h, Component } from 'preact'
import { Icon } from './Icon'

import Close from '../assets/close.svg'

import './Alert.scss'

type TAlertType = 'info' | 'warning' // | 'error' | 'success'

interface IAlertProps {
    className?: string
    iconGlyph?: string
    closable?: boolean
    onClose?: () => void
    title: string
    type?: TAlertType
    [key: string]: any
}

// eslint-disable-next-line react/prefer-stateless-function
export class Alert extends Component<IAlertProps> {
    static displayName = 'Alert'
    static defaultProps: Partial<IAlertProps> = {
        className: 'pt-widget-alert',
        closable: false,
    }
    render() {
        const {
            className,
            iconGlyph,
            closable,
            title,
            type,
            onClose,
            children,
            ...rest
        } = this.props
        const classNameUse = classNames({ [className]: true, [`pt-widget-alert--${type}`]: type })
        const c = children || title
        return (<div class={classNameUse} {...rest}>
            {iconGlyph ? <Icon glyph={iconGlyph} data-icon="pre" /> : null}
            <p className="pt-widget-alert__message">{c}</p>
            {closable ? <Icon glyph={Close.id} style={{ cursor: 'pointer' }} data-icon="close" onClick={onClose} /> : null}
        </div>)
    }
}
