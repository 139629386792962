import { h, Component } from 'preact'

import { Translation } from '../../preact-i18n'
import type { MultiTenantGuideRemindService } from './service'
import { ThemeContext } from '../../context'
import _ from 'lodash';

interface IMultiTenantGuideRemindProps {
    service: MultiTenantGuideRemindService
    debug?: boolean
}

export class MultiTenantGuideRemind extends Component<IMultiTenantGuideRemindProps> {
    state = {
        loading: false,
        error: false,
        data: null,
        closed: false,
    }

    private setError = (error) => {
        const {
            debug,
        } = this.props
        if (debug) {
            console.error('MultiTenantGuideRemind:', error)
        }
        this.setState({
            error,
        })
    }

    componentWillMount() {
        const { service } = this.props
        this.setState({
            loading: true,
        })

        service.observer(() => {
            service.getQueryMultiTenantGuide().then(data => {
                if (!data) {
                    return
                }
                this.setState({
                    data,
                })
            }, this.setError).finally(() => {
                this.setState({
                    loading: false,
                })
            })
        })
    }


    onConfirm = () => {
        const {
            service,
        } = this.props
        this.setState({
            closed: true,
        })
        service.putMultiTenantGuide().catch(this.setError)
    }

    render(props: IMultiTenantGuideRemindProps) {
        const {
            error,
            loading,
            data,
            closed,
        } = this.state
        if (closed) {
            return null
        }

        if (!loading && !error && data) {
            return (
                <Translation>
                    {(t, { locale }) => {
                        return <div class="pt-multi-tenant-guide__wrapper">
                            <div class="pt-multi-tenant-guide__box">
                                <img src={require(`../../assets/multiTenantGuide/guide${_.upperFirst(locale)}.png`).default} alt="" />
                                <p>{t('multiTenantGuideRemind.tip')}</p>
                                <div class="pt-multi-tenant-guide__btns">
                                    <ThemeContext.Consumer>
                                        {theme =>
                                            <button class="pt-multi-tenant-guide__btns-confirm" data-theme={theme} onClick={this.onConfirm}>
                                                {t('common.gotIt')}
                                            </button>
                                        }
                                    </ThemeContext.Consumer>
                                </div>
                                <div class="triangle" />
                            </div>
                        </div>
                    }}
                </Translation>
            )
        }
        // todo!!
        return null

    }
}