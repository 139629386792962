import { h, Component } from 'preact'

import type { PublishRemindService } from './service'
import { Translation } from '../../preact-i18n'
import { formatDate, formatTimeRange, getTimezone } from '../../utils/datetime'
import type { IReleasePlainItem } from './types'

import './style.scss'

import CloseIcon from '../../assets/close.svg'
import { Icon } from '../../components/Icon'
import { ThemeContext } from '../../context'

interface IPublichRemindProps {
    service: PublishRemindService
    debug?: boolean
}

export class PublichRemind extends Component<IPublichRemindProps> {
    state = {
        loading: false,
        error: false,
        data: null as IReleasePlainItem,
        closed: false,
    }

    private setError = (error) => {
        const {
            debug,
        } = this.props
        if (debug) {
            console.error('PublishRemind:', error)
        }
        this.setState({
            error,
        })
    }

    componentWillMount() {
        const {
            service,
        } = this.props
        this.setState({
            loading: true,
        })
        service.getQueryReleasePlan().then(data => {
            if (!data) {
                return
            }
            this.setState({
                data,
            })
        }, this.setError).finally(() => {
            this.setState({
                loading: false,
            })
        })
    }

    onClose = () => {
        const {
            service,
        } = this.props
        this.setState({
            closed: true,
        })
        service.postCloseReleasePlan().catch(this.setError)
    }

    render(props) {
        const {
            error,
            loading,
            data,
            closed,
        } = this.state

        if (closed) {
            return null
        }
        if (!loading && !error && data) {
            return (
                <Translation>
                    {(t, { dayjs_locale }) => {
                        const {
                            begin_at,
                            end_at,
                        } = data
                        const date = formatDate(begin_at, dayjs_locale)
                        const timeRange = formatTimeRange(begin_at, end_at, dayjs_locale)
                        const timezone = ` (${getTimezone()})`
                        return <div className='pt-widget-dialog__wrapper pt-publish-remind'>
                            <div className='pt-widget-dialog'>
                                <h3 className='pt-publish-remind__title'>{t('publishRemindTitleFormat', { date })}</h3>
                                <h4 className='pt-publish-remind__head'>{t('publishRemindHead')}</h4>
                                <p>{t('publishRemindContentFormat')}</p>
                                <p className='pt-publish-remind__update-info'><b>{t('publishRemindTimeRange')}</b>{timeRange}{timezone}</p>
                                <p className='pt-publish-remind__tip'>{t('publishRemindTip')}</p>
                                <div className='pt-widget-dialog__operators'>
                                    <ThemeContext.Consumer>
                                        {theme =>
                                            <button className='pt-widget-button' data-type="primary" data-theme={theme} onClick={this.onClose}>{t('publishRemindOk')}</button>
                                        }
                                    </ThemeContext.Consumer>
                                </div>
                                <div className='pt-widget-dialog__close'>
                                    <Icon glyph={CloseIcon.id} onClick={this.onClose} />
                                </div>
                            </div>
                        </div>
                    }}
                </Translation>)
        }
        // todo!!
        return null

    }
}
