import type { FunctionComponent } from 'preact';
import { h } from 'preact'
import { noop } from '../utils/functions'

import './Icon.scss'

interface IIconProps {
    className?: string
    glyph: string
    onClick?: () => void
    [key: string]: any
}
export const Icon: FunctionComponent<IIconProps> = ({ className, glyph, onClick, ...rest }) => {
    return (<svg className={className} {...rest} onClick={onClick}>
        <use xlinkHref={`#${glyph}`} />
    </svg>)
}
Icon.displayName = 'Icon'
Icon.defaultProps = {
    className: 'widget-icon',
    glyph: '',
    onClick: noop,
}
