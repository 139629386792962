import {VerticalDraggable} from './VerticalDraggable';

const styles = require('!!raw-loader!./style.css').default;
const btnSvg = require('!!raw-loader!./button.svg').default;
const colBtnSvg = require('!!raw-loader!./col-btn.svg').default;
export const componentName = 'ps-cs-btn';

export class PsCsBtnElement extends HTMLElement {
    // 组件的构造函数
    private state: { hasNewMessage: boolean, collapsed: boolean };
    constructor() {
        super(); // 调用父类的constructor
        this.attachShadow({ mode: 'open' }); // 附加一个shadow DOM

        this.state = {
            hasNewMessage: false,
            collapsed: false,
        };

        // 绑定事件处理器的上下文
        this.handleClick = this.handleClick.bind(this);
    }

    // 连接到DOM时调用，可以在这里绑定事件
    connectedCallback() {
        this.render();
        this.shadowRoot.addEventListener('click', this.handleClick);
        this.listenUnread()
        this.bindDrag(true)
    }

    // 从DOM中移除时调用，可以在这里解绑事件
    disconnectedCallback() {
        this.shadowRoot.removeEventListener('click', this.handleClick);
        this.bindDrag(false)
    }
    
    get namespace() {
        return this.getAttribute('namespace')
    }

    private verticalDraggable:VerticalDraggable = undefined
    private bindDrag(bol:boolean) {
        if(this.verticalDraggable) {
            this.verticalDraggable.destroy();
            this.verticalDraggable = undefined
        }
        if(bol) {
            this.verticalDraggable = new VerticalDraggable(this)
        }
    }

    private listenUnread() {
        const self = this;
        switch (this.namespace) {
        case 'ysf':
            window.ysf('onunread',  (result) => {
                const hasNewMessage = result.total > 0;
                if(hasNewMessage !== self.state.hasNewMessage) {
                    self.state.hasNewMessage = hasNewMessage;
                    self.render()
                }
            });
            break;
        }
    }

    // 处理点击事件
    handleClick() {
        if(this.verticalDraggable?.isReallyDragging) {
            return
        }
        this.state.hasNewMessage = false
        switch (this.namespace) {
        case 'ysf':
            window.ysf('open')
            break;
        }
        this.render(); // 重新渲染
    }

    toggleCollapse(evt) {
        evt.stopPropagation();
        this.state.collapsed = !this.state.collapsed;
        this.render();
    }

    // 绑定折叠按钮事件
    private _colHandlerUnsub: () => void = () => null;
    bindColBtn(bol: boolean) {
        this._colHandlerUnsub();
        if(bol) {
            const cbtn = this.shadowRoot.querySelector('.ps-qiyu-btn_collapse_btn')
            const fn = this.toggleCollapse.bind(this)
            cbtn.addEventListener('click', fn);
            this._colHandlerUnsub = () => cbtn.removeEventListener('click', fn);
        }
    }

    // 渲染组件
    render() {
        this.shadowRoot.innerHTML = `
      <style>
        :host {
          display: block;
          cursor: pointer;
          height: 36px;
            position: fixed;
            right: 0;
            bottom: 3rem;
             border-top-left-radius: 18px;
          border-bottom-left-radius: 18px;
          background: var(--brand-primary, #45a321);
        }
        ${styles}
      </style>
      <div class="ps-qiyu-btn-wrap" data-has-noti="${this.state.hasNewMessage}" 
            data-collapse="${this.state.collapsed}">
            <button type="button" class="ps-qiyu-btn_collapse_btn">${colBtnSvg}</button>
          <div class="ps-qiyu-btn_circle">${btnSvg}</div>
          <span class="btn-text">在线咨询</span>
      </div>
    `;

        this.bindColBtn(true);
    }
}

export default function register() {

    if (!customElements.get(componentName)) {
        // 如果没有，注册自定义元素
        customElements.define(componentName, PsCsBtnElement);
    } else {
        // 如果已经注册，可以选择打印一条警告或者什么也不做
        console.warn(`${componentName} 已经被注册`);
    }

}
