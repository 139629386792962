import { I18n } from '../mini-i18n'

import commonCN from './common/cn.json'
import commonEN from './common/en.json'
import commonTW from './common/tw.json'
import commonJP from './common/jp.json'

import expirationCN from './expirationRemind/cn.json'
import expirationEN from './expirationRemind/en.json'
import expirationTW from './expirationRemind/tw.json'
import expirationJP from './expirationRemind/jp.json'

import publishCN from './publishRemind/cn.json'
import publishEN from './publishRemind/en.json'
import publishTW from './publishRemind/tw.json'
import publishJP from './publishRemind/jp.json'

import browserUpdateRemindCN from './browserUpdateRemind/cn.json'
import browserUpdateRemindEN from './browserUpdateRemind/en.json'
import browserUpdateRemindTW from './browserUpdateRemind/tw.json'
import browserUpdateRemindJP from './browserUpdateRemind/jp.json'

import legalTermsUpdateRemindCN from './legalTermsUpdateRemind/cn.json'
import legalTermsUpdateRemindEN from './legalTermsUpdateRemind/en.json'
import legalTermsUpdateRemindTW from './legalTermsUpdateRemind/tw.json'
import legalTermsUpdateRemindJP from './legalTermsUpdateRemind/jp.json'

import multiTenantGuideRemindCN from './multiTenantGuideRemind/cn.json'
import multiTenantGuideRemindEN from './multiTenantGuideRemind/en.json'
import multiTenantGuideRemindTW from './multiTenantGuideRemind/tw.json'
import multiTenantGuideRemindJP from './multiTenantGuideRemind/jp.json'

import unauthorizedLoginRemindCN from './unauthorizedLoginRemind/cn.json'
import unauthorizedLoginRemindEN from './unauthorizedLoginRemind/en.json'
import unauthorizedLoginRemindTW from './unauthorizedLoginRemind/tw.json'
import unauthorizedLoginRemindJP from './unauthorizedLoginRemind/jp.json'

import aiTaggingTourCN from './ai-tagging-tour/cn.json'
import aiTaggingTourEN from './ai-tagging-tour/en.json'
import aiTaggingTourTW from './ai-tagging-tour/tw.json'
import aiTaggingTourJP from './ai-tagging-tour/jp.json'


export function createI18n(locale: string) {
    return new I18n({
        locale: locale || 'en',
        fallbackLocale: 'en',
        messages: {
            cn: {
                ...commonCN,
                ...expirationCN, ...publishCN, ...browserUpdateRemindCN, ...legalTermsUpdateRemindCN, ...multiTenantGuideRemindCN, ...unauthorizedLoginRemindCN, ...aiTaggingTourCN,
            },
            en: {
                ...commonEN,
                ...expirationEN, ...publishEN, ...browserUpdateRemindEN, ...legalTermsUpdateRemindEN, ...multiTenantGuideRemindEN, ...unauthorizedLoginRemindEN, ...aiTaggingTourEN,
            },
            jp: {
                ...commonJP,
                ...expirationJP, ...publishJP, ...browserUpdateRemindJP, ...legalTermsUpdateRemindJP, ...multiTenantGuideRemindJP, ...unauthorizedLoginRemindJP, ...aiTaggingTourJP,
            },
            tw: {
                ...commonTW,
                ...expirationTW, ...publishTW, ...browserUpdateRemindTW, ...legalTermsUpdateRemindTW, ...multiTenantGuideRemindTW, ...unauthorizedLoginRemindTW, ...aiTaggingTourTW,
            },
        },
    })
}
