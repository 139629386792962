import urlJoin from 'url-join'

export function buildUrls(data: { accountServerBase: string, serverBase: string }) {
    return {
        getQueryLegalTermsUpdate: urlJoin(data.accountServerBase, '/user-service/accounts/terms/version'),
        putLegalTermsUpdate: urlJoin(data.accountServerBase, '/user-service/accounts/terms/version'),
        getLogout: urlJoin(data.serverBase, '/logout'),
    }
}

export type IUrls = ReturnType<typeof buildUrls>
