import _ from 'lodash';
import type { Http } from '../../http';
import type { IUrls } from './urls';
import { TokenService } from '../../services/TokenService';

interface ICacheValue {
    key: string
    value: any
}

const StorageKey = 'COMMON-WIDGET-MULTI-TENANT-GUIDE'

export class MultiTenantGuideRemindService {
    constructor(
        private http: Http,
        private urls: IUrls,
        private from: string,
        private client_id?: string
    ) {
    }

    private tokenService: TokenService = new TokenService()

    /**
     * 获取是否发送请求的key
     */
    getCheckUpdateKey() {
        return this.tokenService?.session
    }

    getCacheValue() {
        const value = localStorage.getItem(StorageKey)
        if (!value) {
            return {}
        }
        try {
            const data = JSON.parse(value) as ICacheValue
            if (_.isObject(data)) {
                if (data.key === this.getCheckUpdateKey()) {
                    return data
                }
            }
        } catch (e) {
            return {}
        }
        return {}
    }

    storeCacheValue(data: boolean) {
        try {
            const storageData: ICacheValue = {
                key: this.getCheckUpdateKey(),
                value: data,
            }
            localStorage.setItem(StorageKey, JSON.stringify(storageData))
        } catch (e) {
            // ignore
        }
    }

    // 监听body下是否有class是patsnap-biz-header的这个节点
    observer(observerCallback) {
        // querySelector取patsnap-biz-header这个节点，如果有的就监听，没有的话就继续监听，直到有为止
        const targetNode = document.querySelector('.patsnap-biz-header')
        if (targetNode) {
            observerCallback()
            return
        }
        const config = { attributes: true, childList: true, subtree: true }
        const callback = (mutationsList: any, observer: any) => {
            for (const mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    const targetNode = document.querySelector('.patsnap-biz-header')
                    if (targetNode) {
                        observer.disconnect()
                        observerCallback()
                    }
                }
            }
        }
        const observer = new MutationObserver(callback)
        observer.observe(document.body, config)
    }

    getQueryMultiTenantGuide() {
        const cacheObj = this.getCacheValue() as ICacheValue
        const tenantUpdateValue = cacheObj?.value

        if (tenantUpdateValue !== undefined && !tenantUpdateValue) {
            return Promise.resolve(false)
        }

        if (this.tokenService.isAnonymous || !this.tokenService.isTokenValid) {
            return Promise.resolve(false)
        }

        const url = this.urls.getQueryMultiTenantGuide
        return this.http.request(url, {
            method: 'get',
            withCredentials: false,
        }).then((data: string[]) => {
            this.storeCacheValue(data?.length > 0)
            return data?.length > 0
        })
    }

    putMultiTenantGuide() {
        const url = this.urls.putMultiTenantGuide
        return this.http.request(url, {
            method: 'put',
            withCredentials: false,
        }).then(() => {
            this.storeCacheValue(false)
        })
    }
}

export class MultiTenantGuideRemindServiceMock extends MultiTenantGuideRemindService {
    getQueryMultiTenantGuide() {
        return Promise.resolve().then((data) => {
            return true
        })
    }
}