import { createContext } from 'preact'
import type { IQiyuConfig, IZendeskConfig } from './apps/qiyuOrZendesk/type'
import type { Http } from './http'
import type { LabService } from './services/LabService'
import type { T_Theme } from './types'

export interface IContextData {
    workspaceServerBase: string
    workspaceUrl: string
    accountServerBase: string
    backofficeUri: string
    serverBase: string
    http: Http
    labService: LabService,
    clientId: string
    from: string
    region?: string
    chromeUrl?: string
    firefoxUrl?: string
    edgeUrl?: string
    registerService(key: string, service: any): void

    debug?: boolean
    mockExpiration?: boolean
    mockPublish?: boolean
    mockLegalTermsUpdate?: boolean
    mockMultiTenantGuide?: boolean
    mockUnauthorizedLogin?: boolean
    forceShowBrowerUpdate?: boolean
    disableOpenUrl?: boolean

    qiyuConfig?: IQiyuConfig
    zendeskConfig?: IZendeskConfig
}

export const WidgetContext = createContext<IContextData>(null)

export const ThemeContext = createContext<T_Theme>(null)
