import { Component } from 'preact'
import type { IZendeskConfig } from './type'
import zendesk from './zendesk.new.js';

export interface IZendeskProps {
    key: string
    zendesk_admin_user: string
    zendesk_api_token: string
    zendesk_domain: string
    zendesk_key: string
    config: IZendeskConfig
}

export class Zendesk extends Component<IZendeskProps> {
    get ScriptUrl() {
        const { zendesk_key } = this.props
        return `https://static.zdassets.com/ekr/snippet.js?key=${zendesk_key}`
    }

    componentDidMount(){
        const { zendesk_key, config } = this.props
        zendesk({ZENDESK_KEY: zendesk_key, config})
    }

    render(){
        return null
    }
}