import type {
    AxiosInstance,
    AxiosRequestConfig,
} from 'axios'
import _ from 'lodash'

export class Http {
    constructor(private transfer: AxiosInstance) {
    }
    request<T>(url: string, config?: AxiosRequestConfig) {
        const cfg = _.merge({
            url,
        }, config)
        return this.transfer.request<T>(cfg).then(res => res.data)
    }
}
