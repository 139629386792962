import _ from 'lodash';

export function getCookie(cname: string) {
    const name = `${cname}=`;
    const ca = document.cookie.split(';');
    for (const element of ca) {
        const c = _.trim(element);
        if (c.startsWith(name)) return c.substring(name.length, c.length);
    }
    return '';
}

export function setCookie(cname: string, value: string) {
    const domain = window.location.hostname.split('.').slice(-2).join('.')
    document.cookie = `${cname}=${value};domain=.${domain};path=/;SameSite=None;Secure`
}

//region qiyu或者zendesk是否启用的cookie
export const useQiyuAndZendeskCookie = 'COMMON_WIDGET_USE_QIYU_AND_ZENDESK';

export function getUseQiyuAndZendeskCookie() {
    return getCookie(useQiyuAndZendeskCookie) === '1';
}

export function setUseQiyuAndZendeskCookie(value: boolean) {
    setCookie(useQiyuAndZendeskCookie, value ? '1' : '0');
}
//endregion