import _ from 'lodash';
import YSF from '@neysf/qiyu-web-sdk'
import registerQiyuBtn from './ps-cs-button/ps-cs-btn';
const DISABLE_CLASSNAME = 'no_7_yu';
let blacklistPaths = [];
let blacklistHashes = [];
let ysf;


function isInIframe() {
    try {
        return window.self !== window.top;
    } catch (e) {
        return false;
    }
}

function appendCustomButton() {
    registerQiyuBtn()
    const btn = document.createElement('div')
    btn.innerHTML = '<ps-cs-btn namespace="ysf"></ps-cs-btn>'
    document.body.appendChild(btn)
}

function updateClassname() {
    const filterd = blacklistHashes.filter((item) => {
        return window.location.pathname.indexOf(item.path) === 0 && window.location.hash.indexOf(item.hash) === 0;
    });
    const $body = document.querySelectorAll('body')[0];
    if (filterd.length) {
        $body.classList.add(DISABLE_CLASSNAME);
    } else {
        $body.classList.remove(DISABLE_CLASSNAME);
    }
}


async function main(params) {
    const key = params.key
    const option = params.option
    blacklistPaths = _.get(params.config,'blacklistPaths' ,[])
    blacklistHashes = _.get(params.config,'blacklistHashes' ,[])
    if (_.includes(blacklistPaths, window.location.pathname)) {
        return;
    }

    if (window.qiyu_inited) {
        return;
    }

    window.qiyu_inited = true;
    if (isInIframe()) {
        return;
    }

    //@ts-ignore
    ysf = await YSF.init(key, { hidden: 1 }, undefined)
    ysf.logoff();

    console.log('qiyuoption', option)
    if (option.uid && option.uid !== 'undefined' && option.uid !== 'null') {
        ysf.config(Object.assign({}, option));
    } else {
        ysf.logoff();
    }
    await appendCustomButton()
    window.addEventListener('hashchange', updateClassname);
}

export default main

declare global {
  interface Window {
    qiyu_inited: boolean;
  }
}