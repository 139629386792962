import urlJoin from 'url-join'

export function buildUrls(data: { accountServerBase: string, backofficeUri: string }) {
    return {
        getQiyuOrZendeskInfo: urlJoin(data.accountServerBase, '/user-service/accounts/zendesk/qiyu/info'),
        getBaseInfo: urlJoin(data.accountServerBase, '/user-service/accounts/base/info'),
        backofficeUri: data.backofficeUri,
    }
}

export type IUrls = ReturnType<typeof buildUrls>
