import _ from 'lodash'
interface II18nConfig {
    locale: string
    messages: object
    // default: 'en'
    fallbackLocale?: string
}

export class I18n {
    locale = ''
    messages = {}
    fallbackLocale = ''
    constructor(config: II18nConfig) {
        const {
            locale,
            messages,
            fallbackLocale,
        } = config
        this.locale = locale
        this.messages = messages
        this.fallbackLocale = fallbackLocale || 'en'
    }
    get dayjs_locale(): string {
        const transMap = {
            cn: 'zh-cn',
            en: 'en',
            jp: 'ja',
            tw: 'zh-tw',
        }
        return transMap[this.locale] || 'en'
    }

    join = (items: any[]) => {
        const sepMap = {
            cn: '、',
            en: ', ',
            jp: '、',
            tw: '、',
        }
        return items.join(sepMap[this.locale] || ',')
    }

    t = (key: string, obj?: object) => {
        return this._t(this.locale, key, obj)
    }

    _t(locale: string, key: string, obj?: object) {
        const messagesLocal = this.messages[this.locale]
        if (!_.has(messagesLocal, key)) {
            console.warn(`i18n '${locale}' has not key: ${key}`)
            if (locale === this.fallbackLocale) {
                return key
            } 
            // try fallback
            return this._t(this.fallbackLocale, key, obj)
            
        } 
        const value = messagesLocal[key]
        if (obj) {
            const fn = _.template(value)
            return fn(obj)
        } 
        return value
            
        
    }
}
