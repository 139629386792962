import urlJoin from 'url-join'

export function buildUrls(baseUrl: string) {
    return {
        getPrivilegeRenew: urlJoin(baseUrl, '/public/tips/privilege_renew'),
        putPrivilegeRenew: urlJoin(baseUrl, '/public/tips/privilege_renew'),
        putPrivilegeRevoke: urlJoin(baseUrl, '/public/tips/privilege_renew/revoke_ack'),
    }
}

export type IUrls = ReturnType<typeof buildUrls>
