/* eslint-disable react/no-danger */

import type { FunctionComponent } from 'preact';
import { h } from 'preact';
import { ThemeContext } from '../context';
import { noop } from '../utils/functions'
import './MsgBox.scss'

interface IMsgboxProps {
    /**
     * header图
     */
    img?: string
    className?: string
    title?: string
    message?: string
    confirmButtonText?: string
    cancelButtonText?: string
    hiddenCancel?: boolean
    hiddenConfirm?: boolean
    onCancel?: () => void
    onConfirm?: () => void
    [key: string]: any
}
export const Msgbox: FunctionComponent<IMsgboxProps> = ({ img, title, message, confirmButtonText, cancelButtonText, onCancel, onConfirm, hiddenCancel, hiddenConfirm, className }) => {
    return (<div class='pt-widget-message-box__wrapper'>
        <div class={`pt-widget-message-box ${className}`}>
            {
                img ? (<div class={'pt-widget-message-box__header-img'}>
                    <img src={img} alt="" />
                </div>) : null
            }
            <div class="pt-widget-message-box__body">
                <div class="pt-widget-message-box__title">{title}</div>
                <div class="pt-widget-message-box__content" dangerouslySetInnerHTML={{ __html: message }} />
                <div class="pt-widget-message-box__btns">
                    {
                        hiddenCancel ? null : (<button class="pt-widget-message-box__btns-cancel" onClick={onCancel}>{cancelButtonText}</button>)
                    }
                    {
                        hiddenConfirm ? null : (<ThemeContext.Consumer>
                            {theme =>
                                <button class="pt-widget-message-box__btns-confirm" data-theme={theme} onClick={onConfirm}>{confirmButtonText}</button>
                            }
                        </ThemeContext.Consumer>)
                    }
                </div>
            </div>
        </div>
    </div>)
}

Msgbox.displayName = 'Msgbox'
Msgbox.defaultProps = {
    img: '',
    title: '',
    message: '',
    confirmButtonText: 'Confirm',
    cancelButtonText: 'Cancel',
    className: '',
    onCancel: noop,
    onConfirm: noop,
}