export class VerticalDraggable {
    private isDragging: boolean;
    private relativePosY: number;
    private initialY:number
    private element: HTMLElement;

    private unbind: () => void = () => {}

    private boundingBottom:[number, number] = [0, window.innerHeight - 36 - 64]

    constructor(element:HTMLElement) {
        this.element = element;
        this.isDragging = false;
        this.relativePosY = 0;
    
        // 绑定事件处理函数
        const down = this.onPointerDown.bind(this);
        const move = this.onPointerMove.bind(this);
        const up = this.onPointerUp.bind(this);
        this.element.addEventListener('mousedown', down);
        window.addEventListener('mousemove', move);
        window.addEventListener('mouseup', up);
        this.unbind = () => {
            this.element.removeEventListener('mousedown', down);
            window.removeEventListener('mousemove', move);
            window.removeEventListener('mouseup', up);
        }
    }


    isReallyDragging = false

    onPointerDown(event) {
        this.isDragging = true;
        this.relativePosY = event.clientY - this.element.offsetTop;
        this.boundingBottom[1] = window.innerHeight - 36 - 64

        this.initialY = event.clientY
    }

    onPointerMove(event) {
        if (this.isDragging) {

            let newBottom = window.innerHeight - event.clientY - this.relativePosY;
    
            // 在这里可以添加一些限制，以确保元素不会超出边界
            if (newBottom < this.boundingBottom[0]) {
                newBottom = this.boundingBottom[0];
            }
            if (newBottom > this.boundingBottom[1]) {
                newBottom = this.boundingBottom[1];
            }

            this.element.style.bottom = `${newBottom  }px`;


            if(this.initialY !== event.clientY) {
                this.isReallyDragging = true
            }

        }
       
    }

    onPointerUp(event) {
        this.isDragging = false;
        // 外界要通过它避免触发click
        setTimeout(() => {
            this.isReallyDragging = false;
        }, 0)
    }

    destroy() {
        this.unbind();
    }
}

