import _ from 'lodash'
import { h } from 'preact'
import { WidgetContext } from '../../context'
import { BrowserUpdateRemind } from './BrowserUpdateRemind'
import { BrowserUpdateRemindService } from './service'

export function BrowserUpdateRemindWrapper() {
    return <WidgetContext.Consumer>
        {context => {
            const {
                debug,
                registerService,
                chromeUrl,
                firefoxUrl,
                edgeUrl,
                forceShowBrowerUpdate,
                region,
                disableOpenUrl,
                labService } = context
            let forceShow = forceShowBrowerUpdate
            if (_.isNil(forceShowBrowerUpdate) && debug) {
                forceShow = true
            }
            const service = new BrowserUpdateRemindService({
                forceShow,
                region,
                disableOpenUrl,
                chromeUrl,
                firefoxUrl,
                edgeUrl,
            })
            registerService('browserUpdate', service)
            return <BrowserUpdateRemind service={service} labService={labService} />
        }}
    </WidgetContext.Consumer>
}
