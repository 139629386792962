import urlJoin from 'url-join'

export function buildUrls(baseUrl: string) {
    return {
        getQueryReleasePlan: urlJoin(baseUrl, '/user-service/release-plan/plans'),
        postCloseReleasePlan: urlJoin(baseUrl, '/user-service/release-plan/tip/close'),
        postReopenReleasePlan: urlJoin(baseUrl, '/user-service/release-plan/tip/reopen'),
    }
}

export type IUrls = ReturnType<typeof buildUrls>
