import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import localeData from 'dayjs/plugin/localeData'
import timezone from 'dayjs/plugin/timezone'
import UTC from 'dayjs/plugin/utc'

dayjs.extend(localizedFormat)
dayjs.extend(localeData)
dayjs.extend(timezone)
dayjs.extend(UTC)

import 'dayjs/locale/zh-cn'
import 'dayjs/locale/ja'
import 'dayjs/locale/en'
import 'dayjs/locale/zh-tw'

// utc时间 
// Ex:2023-12-31T15:59:59.000Z
export type T_UTCTime = string

export function formatDate(date: T_UTCTime | Date, dayjs_locale: string) {
    if (dayjs_locale === 'en' || dayjs_locale === 'zh-cn') {
        return dayjs(date).format('YYYY-MM-DD')
    }
    return dayjs(date).locale(dayjs_locale).format('LL')
}

export function formatTimeRange(beginTime: T_UTCTime, endTime: T_UTCTime, dayjs_locale: string) {
    // 去掉后获取weekdays好像不正确
    dayjs.locale(dayjs_locale)
    const dateStr = dayjs(beginTime).format('LL')
    const weekday = dayjs().locale(dayjs_locale).localeData().weekdays(dayjs(beginTime))
    const beginTimeStr = dayjs(beginTime).locale(dayjs_locale).format('HH:mm')
    const endTimeStr = dayjs(endTime).locale(dayjs_locale).format('HH:mm')
    if (dayjs_locale === 'zh-cn' || dayjs_locale === 'zh-tw') {
        return `${dateStr} （${weekday}） ${beginTimeStr} -- ${endTimeStr}`
    }

    return `${dateStr} (${weekday}) ${beginTimeStr} -- ${endTimeStr}`
}

// 获取当前时区 
export function getTimezone() {
    const offsetMinutes = dayjs().utcOffset()
    const hours = Math.abs(Math.floor(offsetMinutes / 60));
    const minutes = Math.abs(offsetMinutes % 60);
    const sign = offsetMinutes < 0 ? '-' : '+';
    const formattedOffset = `${sign}${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    const timezoneName = dayjs.tz.guess();
    return `(GMT${formattedOffset}) ${timezoneName}`;
}