import { getUseQiyuAndZendeskCookie, setUseQiyuAndZendeskCookie, useQiyuAndZendeskCookie } from './cookie';
import { guessEnv } from './environment';

const QiyuOrZendeskDisabledEnv = ['qa', 'release', 'stage', 'ci', 'dev'];
export function getUseQiyuOrZendesk(use: boolean) {
    if (!use) return false;
    if (QiyuOrZendeskDisabledEnv.includes(guessEnv())) {
        if (getUseQiyuAndZendeskCookie()) {
            return true;
        }
        setUseQiyuAndZendeskCookie(false);
        console.warn(`qiyuOrZendesk is disabled in the ${QiyuOrZendeskDisabledEnv.join('、')} environments. To enable it, set the value of ${useQiyuAndZendeskCookie} to 1.`);
        return false;
    }
    return use
}