import { ExpirationRemindService } from '../service'
import type {
    IDeletePrivilegeRenewParams,
    IGetPrivilegeRenewData,
    IGetPrivilegeRenewParams,
    IPutPrivilegeRenewParams,
} from '../types';
import {
    E_NoticeCode,
} from '../types'

export class ExpirationRemindServiceMock extends ExpirationRemindService {
    async getPrivilegeRenew(params?: IGetPrivilegeRenewParams) {
        return {
            user_id: '',
            company_id: '',
            product_id: '',
            session_id: '',
            company_privilege_id: '',
            expired_at: '2023-12-31T15:59:59.000Z',
            notice: true,
            latest_notice: '2023-12-31T15:59:59.000Z',
            tip_code: E_NoticeCode.ap_renew,
        }
    }
    async putPrivilegeRenew(data: IGetPrivilegeRenewData, params?: IPutPrivilegeRenewParams) {
        return
    }
    async deletePrivilegeRenew(params?: IDeletePrivilegeRenewParams) {
        return
    }
}
