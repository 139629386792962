import type { AxiosInstance } from 'axios'
import _ from 'lodash'
import { h, render } from 'preact'
import { ExpirationRemindWrapper } from './apps/expirationRemind/index'
import Cookies from 'js-cookie'
import { PublishRemindWrapper } from './apps/publishRemind/index'
import { BrowserUpdateRemindWrapper } from './apps/browserUpdateRemind/index'
import { AlertGroup } from './components/AlertGroup'
import type { IContextData } from './context';
import { ThemeContext, WidgetContext } from './context'
import { Http } from './http'
import { createI18n } from './i18n/i18n'
import { I18nContext } from './preact-i18n'
import Version from './version.json'

import './style.scss'
import type { E_Region } from './utils/environment';
import { guessConfig, guessFrom, guessRegion, guessTheme, IS_DEBUG } from './utils/environment'
import { LabService, LabServiceEmpty } from './services/LabService'
import type { T_Theme } from './types'
import { LegalTermsUpdateRemindWrapper } from './apps/legalTermsUpdateRemind/index'
import type { IQiyuConfig, IZendeskConfig } from './apps/qiyuOrZendesk/type'
import { QiyuOrZendeskWrapper } from './apps/qiyuOrZendesk'
import { MultiTenantGuideRemindWrapper } from './apps/multiTenantGuideRemind'
import { UnauthorizedLoginRemindWrapper } from './apps/unauthorizedLoginRemind'
import { AiTaggingTourWrapper } from './apps/ai-tagging-tour'
import { getUseQiyuOrZendesk } from './utils/qiyuOrZendesk'

const LOCALE_KEY = '---uniform-setting-locale'

interface IWidgetConfig {
    /**
     * 如果不存在会从locale("---uniform-setting-locale")获取
     */
    locale?: string
    clientId: string
    from?: string,
    axios: AxiosInstance
    theme?: T_Theme
    /**
     * passport service
     */
    serverBase: string
    /**
     * account service, 不传的话直接根据url猜测 
     */
    accountServerBase?: string

    /**
     * workspace service, 不传的话直接根据url猜测 
     */
    workspaceServerBase?: string
    workspaceUrl?: string

    backofficeUri?: string
    /*
     * lab服务器地址, 不传的话直接根据url猜测
     */
    labServerBase?: string
    /**
     * 
     */
    anchor_id?: string



    region?: E_Region
    /**
     * 是否使用过期提示
     * default: true
     */
    useExpirationRemind?: boolean
    /**
     * 是否使用发版提示
     * default: true
     */
    usePublishRemind?: boolean
    /**
     * 是否使用浏览器更新提示
     * default: true
     */
    useBrowerUpdateRemind?: boolean
    /**
     * 是否使用法律条款更新提示
     * default: true
     */
    useLegalTermsUpdateRemind?: boolean
    /**
     * 是否使用qiyu或zendesk客服服务
     * default: false
     */
    useQiyuOrZendesk?: boolean

    /**
     * 是否使用多租户引导
     */
    useMultiTenantGuide?: boolean

    /**
     * 异常登录提醒
     * default: true
     */
    useUnauthorizedLoginRemind?: boolean

    /**
     * AI标引功能引导
     */

    useAiTaggingTour?: boolean

    qiyuConfig?: IQiyuConfig,
    zendeskConfig?: IZendeskConfig,
    /**
     * chrome浏览器地址
     */
    chromeUrl?: string,
    /**
     * firefox浏览器地址
     */
    firefoxUrl?: string,
    /**
     * edge浏览器地址
     */
    edgeUrl?: string,

    container?: HTMLElement
    // for debug/test
    debug?: boolean
    mockExpiration?: boolean
    mockPublish?: boolean
    mockLegalTermsUpdate?: boolean
    mockMultiTenantGuide?: boolean
    mockUnauthorizedLogin?: boolean
    /**
     * 强制显示浏览器更新提示
     */
    forceShowBrowerUpdate?: boolean
    /**
     * 阻止点击更新跳转
     */
    disableOpenUrl?: boolean
}

function normalizeConfig(config: IWidgetConfig) {
    const debug = config.debug || IS_DEBUG
    if (debug) {
        console.log('backoffice-widget:version', Version)
        console.log('backoffice-widget:config', config)
    }
    const normalConfig = _.defaults(config, {
        locale: Cookies.get(LOCALE_KEY) || 'en',
        serverBase: '/', // make url-join happy
        useExpirationRemind: true,
        usePublishRemind: true,
        useBrowerUpdateRemind: true,
        useLegalTermsUpdateRemind: true,
        useQiyuOrZendesk: false,
        useMultiTenantGuide: true,
        useUnauthorizedLoginRemind: true,
        useAiTaggingTour: false,
        theme: guessTheme(),
        from: guessFrom(),
        region: guessRegion(),
        ...guessConfig(),
    }) as IWidgetConfig
    config.debug = debug
    if (!_.includes(['en', 'cn', 'jp', 'tw'], normalConfig.locale)) {
        normalConfig.locale = 'en'
    }
    if (!normalConfig.container) {
        const container = document.createElement('div')
        container.id = '$$widget-container$$'
        document.body.appendChild(container)
        normalConfig.container = container
    }
    const {
        mockExpiration,
        mockPublish,
        mockLegalTermsUpdate,
        mockMultiTenantGuide,
        mockUnauthorizedLogin,
    } = normalConfig
    normalConfig.mockExpiration = debug && mockExpiration
    normalConfig.mockPublish = debug && mockPublish
    normalConfig.mockLegalTermsUpdate = debug && mockLegalTermsUpdate
    normalConfig.mockMultiTenantGuide = debug && mockMultiTenantGuide
    normalConfig.mockUnauthorizedLogin = debug && mockUnauthorizedLogin
    if (debug) {
        console.log('backoffice-widget:normalConfig', normalConfig)
    }
    return normalConfig
}

function checkConfig(config: IWidgetConfig) {
    type K = keyof IWidgetConfig
    const requiredKeys = ['axios', 'clientId', 'serverBase'] as K[]
    for (let i = 0, len = requiredKeys.length; i < len; i++) {
        const key = requiredKeys[i]
        if (_.isNil(config[key])) {
            console.error('backoffice-widget:config', `${key} missing`)
            return false
        }
    }
    return true
}

export function init(config: IWidgetConfig) {
    config = normalizeConfig(config)
    if (!checkConfig(config)) {
        return
    }

    const {
        locale,
        clientId,
        from,
        workspaceServerBase,
        workspaceUrl,
        accountServerBase,
        backofficeUri,
        serverBase,
        labServerBase,
        axios,
        region,
        container,
        useExpirationRemind,
        usePublishRemind,
        useBrowerUpdateRemind,
        useLegalTermsUpdateRemind,
        useQiyuOrZendesk,
        useMultiTenantGuide,
        useUnauthorizedLoginRemind,
        useAiTaggingTour,
        qiyuConfig,
        zendeskConfig,
        chromeUrl,
        firefoxUrl,
        edgeUrl,
        debug,
        mockExpiration,
        mockPublish,
        mockLegalTermsUpdate,
        mockMultiTenantGuide,
        mockUnauthorizedLogin,
        forceShowBrowerUpdate,
        disableOpenUrl,
        anchor_id,
        theme,
    } = config
    if (debug) {
        console.log('debug.....')
    }
    const http = new Http(axios)
    const i18n = createI18n(locale)
    let labService: LabService
    if (labServerBase && anchor_id) {
        labService = new LabService(axios, labServerBase, anchor_id)
    } else {
        labService = new LabServiceEmpty(axios, labServerBase, anchor_id)
    }

    const services = {
        labService,
    }

    const context: IContextData = {
        workspaceServerBase,
        workspaceUrl,
        accountServerBase,
        backofficeUri,
        serverBase,
        clientId,
        from,
        http,
        labService,
        debug,
        mockExpiration,
        mockPublish,
        mockLegalTermsUpdate,
        mockMultiTenantGuide,
        mockUnauthorizedLogin,
        chromeUrl,
        firefoxUrl,
        edgeUrl,
        forceShowBrowerUpdate,
        disableOpenUrl,
        region,
        qiyuConfig,
        zendeskConfig,
        registerService(key: string, service: any) {
            services[key] = service
        },
    }

    const _useQiyuOrZendesk = getUseQiyuOrZendesk(useQiyuOrZendesk);

    render(<WidgetContext.Provider value={context}>
        <ThemeContext.Provider value={theme}>
            <I18nContext.Provider value={{ i18n }}>
                <div className="backoffice-widget">
                    <AlertGroup location="bottom-center">
                        {useExpirationRemind ? <ExpirationRemindWrapper /> : null}
                    </AlertGroup>
                    {/* more widget */}
                    {useBrowerUpdateRemind ? <BrowserUpdateRemindWrapper /> : null}
                    {usePublishRemind ? <PublishRemindWrapper /> : null}
                    {useLegalTermsUpdateRemind ? <LegalTermsUpdateRemindWrapper /> : null}
                    {_useQiyuOrZendesk ? <QiyuOrZendeskWrapper /> : null}
                    {useMultiTenantGuide ? <MultiTenantGuideRemindWrapper /> : null}
                    {useUnauthorizedLoginRemind ? <UnauthorizedLoginRemindWrapper /> : null}
                    {useAiTaggingTour ? <AiTaggingTourWrapper /> : null}
                </div>
            </I18nContext.Provider>
        </ThemeContext.Provider>
    </WidgetContext.Provider>, container)
    window['$BackofficeWidgetServices'] = services

    return () => {
        if (debug) {
            delete window['$BackofficeWidgetServices']
        }
        render('', container)
        if (container.id === '$$widget-container$$') {
            document.body.removeChild(container)
        }
    }
}

export const versionInfo = Version