import type { Http } from '../../http';
import type { I_AiTaggingTourStatus, I_UpdateAiTaggingTourStatusParams } from './type';
import type { IUrls } from './urls';

const COMMON_WIDGET_AI_TAGGING_TOUR_STATUS = 'COMMON_WIDGET_AI_TAGGING_TOUR_STATUS'

export class AiTaggingTourService {
    constructor(
        private http: Http,
        private urls: IUrls
    ) {
    }

    getAiTaggingTourStatus(): Promise<I_AiTaggingTourStatus> { 
        const cacheData = this.getAiTaggingTourStatusFromCache()
        if (cacheData) { 
            return Promise.resolve(cacheData)
        }
        return this.http.request(this.urls.aiTaggingTourStatus, {
            method: 'GET',
            headers: { 'X-API-Version': '2.0' },
        }).then((res: any) => {
            this.cacheAiTaggingTourStatus(res.data)
            return res.data
        })
    }

    closeAiTaggingTour() { 
        this.updateAiTaggingTourStatus()
        this.cacheAiTaggingTourStatus({
            show_ai_tagging_tour: false,
        })
    }

    cacheAiTaggingTourStatus(status: I_AiTaggingTourStatus) { 
        const now_time = new Date().getTime()
        localStorage.setItem(COMMON_WIDGET_AI_TAGGING_TOUR_STATUS, JSON.stringify({
            time: now_time,
            data: status,
        }))
    }

    getAiTaggingTourStatusFromCache(): I_AiTaggingTourStatus | null { 
        const cache = localStorage.getItem(COMMON_WIDGET_AI_TAGGING_TOUR_STATUS)
        if (!cache) {
            return null
        }
        const { time, data } = JSON.parse(cache)
        const now_time = new Date().getTime()
        if (now_time - time > 1000 * 60 * 60 * 24) {
            return null
        }
        return data
    }

    updateAiTaggingTourStatus(): Promise<void> {
        return this.http.request(this.urls.aiTaggingTourStatus, {
            method: 'POST',
            headers: { 'X-API-Version': '2.0' },
            data: {
                show_ai_tagging_tour: true,
            },
        })
    }
}

export class AiTaggingTourServiceMock extends AiTaggingTourService {
    getAiTaggingTourStatus(): Promise<I_AiTaggingTourStatus> { 
        const cacheData = this.getAiTaggingTourStatusFromCache()
        if (cacheData) { 
            return Promise.resolve(cacheData)
        }
        return Promise.resolve({
            show_ai_tagging_tour: true,
        })
    }
}